import { useNavigate } from "react-router-dom";
import styles from "@scss/pages/main/team.module.scss";
import bracketSVG from "../../assets/svg/bracket.svg";

export default function Team() {
  const navigate = useNavigate();

  const handleToTeam = () => {
    navigate("/about/team?lang=en");
    window.scrollTo(0, 0);
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.title}>The team</div>

      <div className={styles.des}>
        Best of breed technology with proven record on expert & experience
      </div>

      <ul>
        <li className={styles.pannel}>
          <img src="/img/team/대표님.png" alt="profile" />

          <div className={styles.profile}>
            <div className={styles.name}>Michael Lee</div>
            <div className={styles.position}>CEO</div>
          </div>
        </li>

        <li className={styles.pannel}>
          <img src="/img/team/창기님.png" alt="profile" />

          <div className={styles.profile}>
            <div className={styles.name}>Wayne Jeong</div>
            <div className={styles.position}>CTO</div>
          </div>
        </li>

        <li className={styles.pannel}>
          <img src="/img/team/서영규님.jpg" alt="profile" />

          <div className={styles.profile}>
            <div className={styles.name}>Youngkyu Seo</div>
            <div className={styles.position}>CISO / CPO</div>
          </div>
        </li>

        <li>
          <button type="button" name="move_team" onClick={handleToTeam}>
            See more <img src={bracketSVG} alt="bracket" />
          </button>
        </li>
      </ul>
    </section>
  );
}
