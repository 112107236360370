import { useRef, useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import S from "@scss/component/main/slider.module.scss";

function MainSlider() {
  const [status, setStatus] = useState("Pause");
  const [currentPage, setCurrentPage] = useState(1);

  const sliderRef = useRef<Slider | null>(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    touchMove: false,
    afterChange: (current: number) => {
      setCurrentPage(current + 1);
    },
  };

  const statusHandler = () => {
    if (status === "Play") {
      setStatus("Pause");
      sliderRef.current!.slickPlay();
    } else {
      setStatus("Play");
      sliderRef.current!.slickPause();
    }
  };

  return (
    <>
      <Slider ref={sliderRef} {...settings}>
        <div className={S.slide}>
          <p>우리는 다음 질문에서 시작하였습니다</p>
          <p>
            내 카드내역서, 내 쇼핑내역...
            <br />
            익명처리된 나의 데이터들을 활용하는 기업들
            <br />
            분명 내 데이터인데,
            <br />
            어째서 아무런 이득 없이 나는 스팸전화만 받고 있을까요?
          </p>
        </div>

        <div className={S.slide}>
          <p>
            에스앤피랩이 <span>그립니다</span>
          </p>
          <p>
            오늘의 행동이 나의 데이터가 되며,
            <br />
            데이터를 통해 내일을 예측하고
            <br />
            합법적으로 데이터를 거래하여
            <br />
            <span>개인이 수익을 얻는 세상, 에스앤피랩이 그립니다</span>
          </p>
        </div>

        <div className={S.slide}>
          <p>
            에스앤피랩이 <span>바꾸려고 합니다</span>
          </p>
          <p>
            에스앤피랩은 그동안 불편하지만 넘겼던 것들,
            <br />
            구조적 문제가 있지만 어쩔 수 없다고 생각했던 것들을
            <br />
            모두 바꾸려고 합니다.
            <br />
            <span>
              처음부터 끝까지 모두! 내 데이터가 언제 어디서나 내 것이기에
            </span>
          </p>
        </div>
      </Slider>

      <div className={S["btn-wrap"]}>
        <span className={S.pagination}>{currentPage} / 3</span>
        <button
          type="button"
          aria-label="prev"
          className={S.prev}
          onClick={() => {
            sliderRef.current!.slickPrev();
          }}
        />
        <button
          type="button"
          aria-label="next"
          className={S.next}
          onClick={() => {
            sliderRef.current!.slickNext();
          }}
        />
        <button
          type="button"
          className={S["play-button-container"]}
          onClick={statusHandler}
        >
          <div
            className={`${S["play-button"]} ${S["pause-before"]} ${
              status === "Play" ? S["play-button-before"] : ""
            }`}
          />
          <div
            className={`${S["play-button"]} ${S["pause-after"]} ${
              status === "Play" ? S["play-button-after"] : ""
            }`}
          />
        </button>
      </div>
    </>
  );
}

export default MainSlider;
