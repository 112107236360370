import { useEffect } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";

import { Footer, Header } from "./component/common";
import {
  Main,
  Tech,
  About,
  Press,
  Service,
  DIDMethodSpec,
  NotFound,
} from "./pages";
import { useTranslation } from "react-i18next";

import "@scss/index.scss";
import MydService from "./pages/Service/MydService";
import Solution from "./pages/Service/Solution";
import Cloud from "./pages/Service/Cloud";

function App() {
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const paramsLang = searchParams.get("lang");

  useEffect(() => {
    if (paramsLang) {
      i18n.changeLanguage(paramsLang);
    } else if (i18n.language !== "ko") {
      i18n.changeLanguage("en");
    }
  }, [i18n, paramsLang]);

  return (
    <div className="wrap">
      <Header />

      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/technology" element={<Tech />} />
        <Route path="/about/*" element={<About />} />
        <Route path="/service" element={<Service />}>
          <Route index element={<MydService />} />
          <Route path="solution" element={<Solution />} />
          <Route path="cloud" element={<Cloud />} />
        </Route>
        <Route path="/press" element={<Press />} />
        <Route path="/snplab-did-method-spec" element={<DIDMethodSpec />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
