import { MainSlider, Card, History, Partners } from "@component/main";

import { BlogIcon, YoutubeIcon } from "src/assets/svg";
import S from "@scss/pages/main.module.scss";

export default function MainKo() {
  return (
    <section className={S.main}>
      <div className={S["main-wrap"]}>
        <div className={S["main-info"]}>
          <div className={S["main-info-wrap"]}>
            <div className={S["main-desc"]}>
              <p>우리는 꿈꿉니다</p>
              <p>
                개인의 데이터가
                <br />
                개인의 자산이 되는 그 날을
              </p>
            </div>

            <div className={S.sns_btn_box}>
              <button
                type="button"
                className={S.youtube}
                onClick={() =>
                  window.open("https://www.youtube.com/@rightdata_myD")
                }
              >
                <YoutubeIcon />
                에스앤피랩 유튜브
              </button>

              <button
                type="button"
                className={S.blog}
                onClick={() =>
                  window.open(
                    "https://inblog.ai/snplab-myd?traffic_type=internal"
                  )
                }
              >
                <BlogIcon />
                블로그 소식
              </button>
            </div>
          </div>
        </div>

        <div className={S.content}>
          <div className={S["content-wrap"]}>
            <article>
              <div className={S["content-header"]}>
                <span>에스앤피랩이 꿈꾸는 세상</span>
                <hr />
              </div>
              <MainSlider />
            </article>

            <article>
              <div className={S["content-header"]}>
                <span>에스앤피랩이 만드는 세상엔...</span>
                <hr />
              </div>
              <Card />
            </article>

            <article>
              <div className={S["content-header"]}>
                <span>에스앤피랩이 걸어온 길</span>
                <hr />
              </div>
              <History />
            </article>

            {/* <article>
                            <div className={S["content-header"]}>
                                <span>마이데이터 서포터즈 이야기</span>
                                <hr />
                            </div>
                            <div className="instagram-wrap">
                                <iframe
                                src="https://cdn.lightwidget.com/widgets/066283aea05252c1bb4a077f171d2de3.html"
                                scrolling="no"
                                allowtransparency="true"
                                title="마이디 인스타그램"
                                className="lightwidget-widget"
                                style={{
                                    width: '100%',
                                    border: 'none',
                                    overflow: 'hidden',
                                }}
                                />
                            </div>
                    </article> */}

            <article>
              <div className={S["content-header"]}>
                <span>협력사</span>
                <hr />
              </div>
              <Partners />
            </article>
          </div>
        </div>
      </div>

      <div className={S.content2}>
        <div className={S["content2-wrap"]}>
          <span>
            <span>에스앤피랩에서 함께 </span>
            <span>성장하실 분을 찾습니다</span>
          </span>
          <button
            type="button"
            onClick={() => {
              window.open(
                "https://www.saramin.co.kr/zf_user/company-info/view?csn=RmdOUWpRaDc1anBxRG5UR2lFMGg2UT09&popup_yn=y"
              );
            }}
          >
            채용 공고 보러 가기
          </button>
        </div>
      </div>
    </section>
  );
}
