import { useTranslation } from "react-i18next";
import MainKo from "./MainKo";
import MainEn from "./MainEn";

function Main() {
  const { i18n } = useTranslation();

  return (
    <>
      {i18n.language === "ko" && <MainKo />}
      {i18n.language !== "ko" && <MainEn />}
    </>
  );
}

export default Main;
