import { useState } from "react";

import { useTranslation } from "react-i18next";

import S from "@scss/component/main/card.module.scss";

function Card() {
  const [isToggle, setIsToggle] = useState(true);

  const { t } = useTranslation();

  return (
    <>
      <div className={S["switch-wrap"]}>
        <button
          type="button"
          id="have"
          className={isToggle ? S["toggle-on-btn"] : S["toggle-off-btn"]}
          onClick={() => setIsToggle(true)}
        >
          있다
        </button>

        <button
          type="button"
          id="none"
          className={isToggle ? S["toggle-off-btn"] : S["toggle-on-btn"]}
          onClick={() => setIsToggle(false)}
        >
          없다
        </button>
      </div>

      {isToggle ? (
        <div className={S.wrap}>
          <div style={{ backgroundImage: "url(/img/card/card1.png)" }}>
            <div className={S.mask}>
              <p>
                개인이 직접 관리하는
                <br />
                마이데이터
              </p>
              <p>
                <span>개인이 소유한 마이데이터 중 </span>
                <span>어떤 데이터를 어느 기업에 제공하고 있는지 </span>
                <span>직접 확인 및 관리할 수 있습니다.</span>
              </p>
            </div>
          </div>

          <div style={{ backgroundImage: "url(/img/card/card2.png)" }}>
            <div className={S.mask}>
              <p>
                마이데이터를 기반으로 하는
                <br />
                나에게 필요한 서비스
              </p>
              <p>
                <span>개인이 소유한 마이데이터를 기반으로 </span>
                <span>개인 니즈에 맞춰 가장 필요한 정보 또는 </span>
                <span>맞춤광고를 제공하고 있습니다.</span>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className={S.wrap}>
          <div style={{ backgroundImage: "url(/img/card/card5.png)" }}>
            <div className={S.mask}>
              <p>
                개인정보
                <br />
                유출걱정
              </p>
              <p>
                개인 데이터를 개인의 스마트폰에만
                <br />
                저장하기 때문에 기업에 의한
                <br />
                데이터 유출 위험이 사라집니다.
              </p>
            </div>
          </div>

          <div style={{ backgroundImage: "url(/img/card/card6.png)" }}>
            <div className={S.mask}>
              <p>
                복잡하기만 한 동의와
                <br />
                회원 가입 절차
              </p>
              <p>
                회원가입 없이 마이디 서비스를
                <br />
                쉽고 빠르게 이용할 수 있습니다.
              </p>
            </div>
          </div>
        </div>
      )}

      {isToggle ? (
        <div className={S.wrap}>
          <div style={{ backgroundImage: "url(/img/card/card3.png)" }}>
            <div className={S.mask}>
              <p>
                정당한 대가를 지불하고
                <br />
                활용하는 마이데이터
              </p>
              <p>
                <span>개인은 기업에게 개인 데이터를 직접 판매하고,</span>
                <span>데이터의 종류, 기간, 횟수 및 목적에 따라</span>
                <span>다양한 수수료를 지급받을 수 있습니다.</span>
              </p>
            </div>
          </div>

          <div style={{ backgroundImage: "url(/img/card/card4.png)" }}>
            <div className={S.mask}>
              <p>
                실시간 활용 가능한
                <br />
                마이데이터
              </p>
              <p>
                <span>플랫폼을 통해 실시간 업데이트 된 정보들을 </span>
                <span>
                  거래할 수 있으며, 이 때문에 가장 빠르게 실시간 데이터를
                </span>
                <span>수집 및 확인하실 수 있습니다.</span>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className={S.wrap}>
          <div style={{ backgroundImage: "url(/img/card/card7.png)" }}>
            <div className={S.mask}>
              <p>
                데이터 관리에
                <br />
                필요한 서버
              </p>
              <p>
                {t(
                  "플랫폼을 통해 실시간 업데이트 된 정보들을 거래할 수 있으며,"
                )}
                <br />
                이 때문에 가장 빠르게 실시간 데이터를
                <br />
                수집 및 확인하실 수 있습니다.
              </p>
            </div>
          </div>

          <div style={{ backgroundImage: "url(/img/card/card8.png)" }}>
            <div className={S.mask}>
              <p>
                오래된
                <br />
                데이터
              </p>
              <p>
                <span>실시간으로 업데이트된 데이터를 바탕으로 </span>
                <span>사용자들이 현재시점에서 가장 필요로 하는 것들이</span>
                <span> 무엇인지 분석을 통해 파악할 수 있습니다.</span>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Card;
