import S from "@scss/component/main/history.module.scss";
import { footPrint } from "src/constants/footPrint";

function History() {
  return (
    <>
      <div className={S["history-wrap"]} aria-label="연혁">
        {Object.entries(footPrint)
          .reverse()
          .map(([year, history]) => {
            return (
              <div key={year} className={S["history-years-wrap"]}>
                <time dateTime="yyyy" className={S["history-year"]}>
                  {year}
                </time>
                <div className={S["history-wrapper"]}>
                  {history.map(({ title, content }) => {
                    return (
                      <div className={S.history} key={title + content}>
                        <span className={S["history-title"]}>{title}</span>
                        <span className={S["history-content"]}>{content}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>

      <iframe
        src="https://snplab.io/latest-media"
        title="최근게시물"
        id="latest-media"
        className={S["latest-media"]}
      />
      {/* <iframe src="http://localhost:8000/latest-media" title="최근게시물" id="latest-media" className={S["latest-Media"]} /> */}
    </>
  );
}

export default History;
