export const footPrint = {
  2024: [
    {
      title: "마이데이터 본인 다운로드 연구방안 과제 수행 기관 선정",
      content: "개인정보보호위원회 / KISA",
    },
  ],
  2023: [
    { title: "경기도 마이데이터 플랫폼 확장 구축", content: "KED" },
    {
      title: "데이터바우처 지원사업 공급기관 선정",
      content: "과학기술정보통신부 / KDATA",
    },
    {
      title: "관광벤처 성장기업 선정",
      content: "한국관광공사 / 문화관광부",
    },
    { title: "성장관광벤처 신입부문 최수우상", content: "한국관광공사" },
    {
      title: "마이데이터 종합기반 조성 사업 선정",
      content: "과학기술정보통신부 / 한국데이터산업진흥원",
    },
    {
      title: "2023 MyData Operator Award",
      content: "마이데이터 협회",
    },
  ],
  2022: [
    {
      title: "정보 보호 스타트업 IR 경연대회 최우수상",
      content: "한국정보보호산업협회",
    },
    {
      title: "시큐리티 어워드 코리아 2022 스타트업 대상",
      content: "시큐리티 어워드 코리아",
    },
    {
      title: "관광벤처 선정",
      content: "한국관광공사",
    },
    { title: "AI 학습용 데이터 구축사업", content: "NIA" },
    {
      title: "이종데이터 결합 활용 헬스 케어 플랫폼 구축",
      content: "산업통상자원부",
    },
    { title: "데이터바우처 지원사업 공급기관 선정", content: "KDATA" },
    {
      title: "이종 산업간 마이데이터 전송 표준화",
      content: "개인정보보호위원회 / KISA",
    },
    { title: "경기도 마이데이터 플랫폼 확장 구축", content: "KED" },
  ],
  2021: [
    {
      title: "데이터 경제를 위한 블록체인 기술 개발사업 선정",
      content: "과학기술정보통신부",
    },
    { title: "민관협력기반 ICT 스타트업 선정", content: "과학기술정보통신부" },
    { title: "범부처 연계형 기술사업화 선정", content: "산업통상자원부" },
    { title: "TIPS 프로그램 선정", content: "중소벤처기업부" },
    {
      title: "2021 마이데이터 실증 사업 선정",
      content: "과학기술정보통신부 / KDATA",
    },
    { title: "경기도 마이데이터 플랫폼 구축", content: "KED" },
    {
      title: "중소벤처기업부 장관상",
      content: "중소벤처기업부 / 중소벤처기업진흥공단",
    },
    {
      title: "서울 청년창업사관학교 11기 선정",
      content: "중소벤처기업진흥공단",
    },
  ],
  2020: [
    { title: "K-Security 최우수팀 선정", content: "한국인터넷진흥원" },
    { title: "K-Global 스타트업 최우수상", content: "정보통신산업진흥원" },
    { title: "정보보호 해커톤 대상", content: "과학기술정보통신부 장관상" },
    { title: "IP(지적재산권) 컴피티션 최우수상", content: "특허청장상" },
    {
      title: "기술상용화 사업 / 캠퍼스타운 기술 매칭 선정",
      content: "서울산업진흥원 / 서울기술연구원",
    },
    { title: "직무발명보상 우수기업 인증", content: "" },
    {
      title: "K-Security 정보보호 \n초기창업 기업 선정",
      content: "과학기술정보통신부",
    },
    {
      title: "마이데이터 실증사업 \n우수 사례 선정",
      content: "과학기술정보통신부 / 마이데이터플랫폼 제공사 NH농협 컨소시엄",
    },
    {
      title: "벤처기업인증",
      content: "기업부설연구소 설립 / \n기술역량 우수기업 인증",
    },
    {
      title: "On-Device 마이데이터 플랫폼 R&D 협약",
      content: "중소기업기술정보진흥원",
    },
    { title: "핀테크 API 사업 협약", content: "한국인터넷진흥원" },
    {
      title: "서울 청년창업사관학교 10기 선정",
      content: "중소벤처기업진흥공단",
    },
  ],
  2019: [
    { title: "법인 설립 (2019년 12월 23일)", content: "" },
    { title: "마이데이터 IR대회", content: "한국데이터산업진흥원, 대상" },
    {
      title: "마이데이터 액팅 해커톤",
      content: "과학기술정보통신부, 최우수상",
    },
    { title: "NH디지털혁신캠퍼스 해커톤", content: "NH은행, 장려상" },
  ],
  2018: [
    {
      title: "개인정보 비식별 기술 경진대회",
      content: "방송통신위원회, 우수상",
    },
    { title: "삼성전자 C-Lab 과제 선정", content: "" },
  ],
};
