import cx from "classnames";
import S from "@scss/pages/service.module.scss";

function ServiceKo() {
  const myd = () => {
    window.open("https://myd.world/");
  };

  const googleMyd = () => {
    window.open("https://play.google.com/store/apps/details?id=io.snplab.myd");
  };

  const iosMyd = () => {
    window.open(
      "https://apps.apple.com/kr/app/%EB%A7%88%EC%9D%B4%EB%94%94/id1576612543"
    );
  };

  const b2bMyd = () => {
    window.open("https://service.biz.myd.world/");
  };

  return (
    <>
      <div className={S.desc}>
        <p>사용자와 기업을 위한 Win-Win 마이데이터 플랫폼</p>
        <p>
          <span>
            사용자는 비식별화된 데이터를 기업에 판매하고 보상을 받을 수 있고,
            기업은 사용자의 데이터를 구매하고 맞춤형 광고를 보낼 수 있는
          </span>
          <span>On-Device MyData 플랫폼 서비스를 제공합니다.</span>
        </p>
      </div>

      <div className={S.info}>
        <img src="/img/mydMock.png" alt="myd" />

        <div>
          <div className={S["info-desc"]}>
            <span>마이디</span>
            <p className={S["info-content"]}>
              사용자는 은행 정보, 카드 사용내역, 의료 기록, 쇼핑 습관, 유튜브
              시청 기록 등
              <br />
              자신의 데이터를 관리하거나 기업에게 비식별화된 데이터를 판매하고
              보상을
              <br />
              받을 수 있습니다.
            </p>
            <button type="button" className={S.btn} onClick={myd}>
              Website
            </button>
          </div>

          <div className={S["info-desc"]}>
            <span>애플리케이션 다운로드</span>
            <div className={S["info-btn-wrap"]}>
              <button type="button" className={S.btn} onClick={googleMyd}>
                Google Play
              </button>
              <button type="button" className={S.btn} onClick={iosMyd}>
                App Store
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={cx(S.info, S.connect_info)}>
        <img
          src="/img/connect.png"
          alt="myd_connect"
          className={S.connect_img_ko}
        />
        <div>
          <div className={S["info-desc"]}>
            <span>마이디 커넥트</span>
            <p className={S["info-content"]}>
              기업은 my:D CONNECT를 통해 사용자의 비식별화된 데이터를 구매 및
              분석할
              <br />수 있고, 사용자에 대한 맞춤형 광고를 등록할 수 있습니다.
            </p>

            <button type="button" className={S.btn} onClick={b2bMyd}>
              Website
            </button>
          </div>
        </div>
      </div>

      <div className={cx(S.info, S.sdk_info)}>
        <img
          src="/img/odmpass.png"
          alt="myd_connect"
          className={S.service_svg}
        />
        <div>
          <div className={S["info-desc"]}>
            <span>ODMPASS SDK</span>
            <p className={S["info-content"]}>
              SNPLab의 ODMPASS(On-Device Mydata Pass) SDK를 기업의 플랫폼과
              연동하여 기업에서 쉽고 안전하게 데이터 전송할 수 있습니다.
            </p>
          </div>
        </div>
      </div>

      <div className={S.key_features}>
        <div className={S.title}>KEY FEATURES</div>

        <dl className={S.info_dl}>
          <div>
            <dt>데이터 마켓</dt>
            <dd className={S.desc}>
              스마트폰 기반의 On-Device PDS(Personal data Store)를 통한 세계
              최초의 데이터 거래 서비스
            </dd>
            <dd>
              <img
                src="/img/maket.png"
                alt="market_place"
                className={S.place_img}
              />
            </dd>
          </div>

          <div>
            <dt>맞춤형 광고 지원</dt>
            <dd className={S.desc}>
              기업은 인구통계, 관심사 또는 선호도와 같은 요소를 기반으로 대상
              정보가 포함된 광고를 등록하여 특정 사용자에게 노출할 수 있습니다.
            </dd>
            <dd>
              <img
                src="/img/adv.png"
                alt="maketing"
                className={S.maketing_img}
              />
            </dd>
          </div>
        </dl>
      </div>
    </>
  );
}

export default ServiceKo;
