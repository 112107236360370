import S from "@scss/component/common/header/Pagenation.module.scss";
import useScroll from "src/hooks/useScroll";

function Pagenation({ tab }: { tab: number }) {
  const { scrollLevel, scrollY } = useScroll();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className={S.wrapper}>
      <img
        className={`${S["page-position"]} ${tab !== 1 && S.hide}`}
        src={`/img/page${
          scrollY - 100 > window.screen.height && scrollLevel === 1
            ? "2"
            : scrollLevel || "1"
        }.png`}
        alt="pagination"
        id="pagination"
      />
      <button
        type="button"
        className={`${S.oval} ${scrollY === 0 && S.hidden}`}
        onClick={scrollToTop}
      >
        TOP
      </button>
    </div>
  );
}

export default Pagenation;
