import { Link, useSearchParams } from "react-router-dom";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import { MENU_NAME } from "src/constants/menu";
import S from "@scss/component/common/header/menu.module.scss";

interface Props {
  open: boolean;
  toggle: Function;
}

const Menu = ({ open, toggle }: Props) => {
  const { i18n } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const lang = searchParams.get("lang") || i18n.language;
  const isLangEn = lang === "en";

  return createPortal(
    <div className={open ? `${S["open-menu"]} ${S.menu}` : `${S.menu}`}>
      {open ? (
        <div>
          <ul>
            {MENU_NAME.map((element) => {
              return (
                <li key={element.id}>
                  <Link
                    to={isLangEn ? `${element.path}?lang=en` : element.path}
                    onClick={() => toggle()}
                  >
                    {element.name}
                  </Link>
                </li>
              );
            })}
          </ul>

          <div className={S.language}>
            Language
            <button
              type="button"
              className={S["language-btn"]}
              onClick={() => {
                setSearchParams({ lang: "ko" });
                toggle();
              }}
            >
              KOREAN
            </button>
            <button
              type="button"
              className={S["language-btn"]}
              onClick={() => {
                setSearchParams({ lang: "en" });
                toggle();
              }}
            >
              ENGLISH
            </button>
          </div>
        </div>
      ) : null}
    </div>,
    document.getElementById("menu")!
  );
};

export default Menu;
