import { useTranslation } from "react-i18next";
import HeaderKo from "./HeaderKo";
import HeaderEn from "./HeaderEn";

function Header() {
  const { i18n } = useTranslation();

  return (
    <>
      {i18n.language === "ko" && <HeaderKo />}
      {i18n.language !== "ko" && <HeaderEn />}
    </>
  );
}

export default Header;
