import S from "@scss/component/common/topBtn.module.scss";
import useScroll from "src/hooks/useScroll";

export default function TopBtn() {
  const { scrollY } = useScroll();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <button
      type="button"
      className={`${S.oval} ${scrollY === 0 && S.hidden}`}
      onClick={scrollToTop}
    >
      TOP
    </button>
  );
}
