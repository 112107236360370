/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import S from "@scss/pages/about/vision.module.scss";
import { useSearchParams } from "react-router-dom";

const { kakao } = window as any;

function Vision() {
  const { t, i18n } = useTranslation();

  const [searchParams] = useSearchParams();
  const lang = searchParams.get("lang") || i18n.language;
  const isLangEn = lang === "en";

  const translate = (txt: string) => {
    if (isLangEn) return t(txt);
    return txt;
  };

  useEffect(() => {
    function displayMarker() {
      if (kakao && kakao.maps) {
        const containerHQ = document.getElementById("mapHQ");
        const containerRnD = document.getElementById("mapRnD");
        const optionsHQ = {
          center: new kakao.maps.LatLng(37.4951092, 127.122443),
          level: 3,
        };
        const optionsRnD = {
          center: new kakao.maps.LatLng(37.4625593, 127.0366509),
          level: 3,
        };

        const markerHQ = new kakao.maps.Marker({
          map: new kakao.maps.Map(containerHQ, optionsHQ),
          position: optionsHQ.center,
        });
        const markerRnD = new kakao.maps.Marker({
          map: new kakao.maps.Map(containerRnD, optionsRnD),
          position: optionsRnD.center,
        });
      } else {
        console.error("Kakao maps API가 로드되지 않았습니다.");
      }
    }

    displayMarker();
  }, []);

  return (
    <>
      <span className={S.vision}>VISION</span>
      <p>
        <span>{translate("개인의 데이터가 ")}</span>
        <span>{translate("개인의 자산이 되다.")}</span>
      </p>

      <span className={S.ci}>CI</span>
      <div className={S["ci-wrap"]}>
        <img src="/img/ci.png" alt="ci" />
        <a
          href="files/SNPLAB_CI.ai"
          target="_blank"
          download
          className={S.button}
        >
          {translate("AI 파일 다운로드")}
        </a>
        <a
          href="files/SNPLAB_CI.pdf"
          target="_blank"
          download
          className={S.button}
        >
          {translate("PDF 파일 다운로드")}
        </a>
      </div>

      <span className={S.location}>{translate("오시는 길")}</span>
      <div className={S["map-wrap"]}>
        <div className={S.map} id="mapHQ" />
        <div className={S.info}>
          <span>
            {translate("주소")}
            <br />
            {translate("본사")} :{" "}
            {translate("서울 송파구 중대로 135 IT벤처타워 서관 10층 1003호")}
          </span>
          <div className={S.vehicle}>
            <div>{translate("지하철")}</div>
            <p>
              {translate("3호선 경찰병원역에서 도보 5분")}
              <br />
              {translate("8호선 가락시장역에서 도보 10분")}
            </p>
          </div>
        </div>
      </div>
      <div className={S["map-wrap"]}>
        <div className={S.map} id="mapRnD" />
        <div className={S.info}>
          <span>
            {translate("주소")}
            <br />
            {translate("연구소")} :{" "}
            {translate("서초구 매헌로 16 하이브랜드 14층 1414호")}
          </span>
          <div className={S.vehicle}>
            <div>{translate("지하철")}</div>
            <p>
              {translate("양재역에서 버스로 15분")}
              <br />
              {translate("양재시민의 숲에서 도보 15분")}
            </p>
          </div>

          <div className={S.vehicle}>
            <div>{translate("버스")}</div>
            <p>
              <span>
                {translate("서초08")} - {translate("양재역 승차 ")}
              </span>
              <span>
                {translate("하이브랜드.코스트코.이마트 정류장(22893)")}
              </span>
              <br />
              <span>
                {translate("서초20")} - {translate("양재역 승차 ")}
              </span>
              <span>
                {translate("하이브랜드.코스트코.이마트 정류장(22893)")}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Vision;
