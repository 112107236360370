import S from "@scss/component/common/footer.module.scss";

export default function FooterKo() {
  const facebook = () => {
    window.open("https://www.facebook.com/MyDataMyD/");
  };

  const insta = () => {
    window.open("https://www.instagram.com/snplab_global/");
  };

  const linkedin = () => {
    window.open("https://www.linkedin.com/company/snplab/");
  };

  const medium = () => {
    window.open("https://myd-app.medium.com/");
  };

  const blog = () => {
    window.open("https://blog.naver.com/mydworld");
  };

  return (
    <footer className={S.wrapper}>
      <div className={S.header}>
        <img src="/img/logo.png" alt="로고" />
        <div className={S.sns}>
          <button type="button" onClick={medium}>
            <img src="/img/medium.svg" alt="medium" />
          </button>
          <button type="button" onClick={facebook}>
            <img src="/img/facebook.png" alt="facebook" />
          </button>
          <button type="button" onClick={insta}>
            <img src="/img/instagram.png" alt="instagram" />
          </button>
          <button type="button" onClick={linkedin}>
            <img src="/img/linkedin.png" alt="linkedin" />
          </button>
          <button type="button" onClick={blog}>
            <img src="/img/blog.png" alt="blog" />
          </button>
        </div>
      </div>

      <div className={S.desc}>
        © SNPLab Inc. All rights reserved.
        <br />
        <div>(주) 에스앤피랩 | 대표 이재영 |&nbsp;</div>
        <div>
          <span>사업자등록번호 889-87-01698 | </span>
          <span>부가통신사업자 제 2-01-20-0274 호 | </span>
        </div>
        통신판매업신고 2020-서울성동-02763 |
        <span>E-mail hellodata@snplab.io | </span>
        <div>
          <span>T 02-2038-2511 | </span>
          <span>F 050-7086-2235 |</span>
        </div>
        <div>본사 : 서울 송파구 중대로 135 IT벤처타워 서관 10층 1003호</div>
        <div>연구소 : 서초구 매헌로 16 하이브랜드 14층 1414호</div>
      </div>
    </footer>
  );
}
