import { NavLink, Outlet, useLocation } from "react-router-dom";
import cx from "classnames";
import S from "@scss/pages/service.module.scss";

function Service() {
  const { pathname } = useLocation();

  return (
    <section className={S.service}>
      <div className={S["service-wrap"]}>
        <p className={S.header}>SERVICE</p>

        <nav className={S["sub-menu"]}>
          <NavLink
            to="."
            className={(_) => (pathname === "/service" ? S.active : "")}
          >
            마이디
          </NavLink>
          <NavLink
            to="./solution"
            className={({ isActive }) => (isActive ? S.active : "")}
          >
            AI Solution
          </NavLink>
          <NavLink
            to="./cloud"
            className={({ isActive }) => cx(S.cloud, { [S.active]: isActive })}
          >
            클라우드 및 H/W 임대 솔루션
          </NavLink>
        </nav>

        <Outlet />
      </div>
    </section>
  );
}

export default Service;
