import styles from "@scss/pages/main/collaboration.module.scss";
import LogoMobile from "./LogoMobile";
import LogoDesktop from "./LogoDesktop";

export default function Collaboration() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.title}>
        We are engaged in a&nbsp;
        <br />
        strategic collaboration with
      </div>

      <div className={styles.mobile}>
        <LogoMobile />
      </div>
      <div className={styles.desktop}>
        <LogoDesktop />
      </div>
    </section>
  );
}
