import cx from "classnames";
import S from "@scss/pages/service.module.scss";
import { OdmpassSdkSvg } from "src/assets/svg";

function ServiceEn() {
  const myd = () => {
    window.open("https://myd.world/");
  };

  const googleMyd = () => {
    window.open("https://play.google.com/store/apps/details?id=io.snplab.myd");
  };

  const iosMyd = () => {
    window.open(
      "https://apps.apple.com/kr/app/%EB%A7%88%EC%9D%B4%EB%94%94/id1576612543"
    );
  };

  const b2bMyd = () => {
    window.open("https://service.biz.myd.world/");
  };

  return (
    <>
      <div className={S.desc}>
        <p>A Win-Win MyData Platform for Users and Businesses</p>
        <p>
          <span>
            We offer an On-Device MyData platform service that allows users to
            sell their de-identified data to businesses and
          </span>
          <span>
            receive rewards, while businesses can buy users’ data and find
            target users in un-identified manner to send tailored
          </span>
          <span>commercial Ads</span>
        </p>
      </div>

      <div className={S.info}>
        <img src="/img/homepage_mockup.png" alt="myd" />

        <div>
          <div className={S["info-desc"]}>
            <span>my:D</span>
            <p className={S["info-content"]}>
              Individuals can control over their own data including Youtube
              watch history, banking information, medical records, shopping
              habits.
            </p>
            <button type="button" className={S.btn} onClick={myd}>
              Website
            </button>
          </div>

          <div className={S["info-desc"]}>
            <span>Application Download</span>
            <div className={S["info-btn-wrap"]}>
              <button type="button" className={S.btn} onClick={googleMyd}>
                Google Play
              </button>
              <button type="button" className={S.btn} onClick={iosMyd}>
                App Store
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={cx(S.info, S.connect_info)}>
        <img
          src="/img/homepage_connect.png"
          alt="myd_connect"
          className={S.connect_img}
        />
        <div>
          <div className={S["info-desc"]}>
            <span>my:D CONNECT</span>
            <p className={S["info-content"]}>
              Data aggregation and connectivity tool for businesses
              <br />
              <br />
              Businesses can buy and analyze de-identified users’ data and
              <br />
              register tailored Ads for targeted users in un-identified way with
              <br />
              my:D CONNECT.
            </p>

            <button type="button" className={S.btn} onClick={b2bMyd}>
              Website
            </button>
          </div>
        </div>
      </div>

      <div className={cx(S.info, S.sdk_info)}>
        <OdmpassSdkSvg className={S.service_svg} />
        <div>
          <div className={S["info-desc"]}>
            <span>ODMPASS SDK</span>
            <p className={S["info-content"]}>
              With integrating the ODMPASS(On-Device Mydata Pass) SDK from
              <br />
              SNPLab with an app, businesses can support data portability easily
              <br />
              and securely.
            </p>
          </div>
        </div>
      </div>

      <div className={S.key_features}>
        <div className={S.title}>KEY FEATURES</div>

        <dl className={S.info_dl}>
          <div>
            <dt>Data Trade Marketplace</dt>
            <dd className={S.desc}>
              The world first commercial rollout for the data trade marketplace
              service on smartphone with the on-device PDS.
            </dd>
            <dd>
              <img
                src="/img/market_place.png"
                alt="market_place"
                className={S.place_img}
              />
            </dd>
          </div>

          <div>
            <dt>Supporting Tailored Ads</dt>
            <dd className={S.desc}>
              Businesses can register advertisements with targeted information,
              allowing them to reach specific audiences based on factors such as
              <br />
              demographics, interests, or preferences.
            </dd>
            <dd>
              <img
                src="/img/maketing.png"
                alt="maketing"
                className={S.maketing_img}
              />
            </dd>
          </div>
        </dl>
      </div>
    </>
  );
}

export default ServiceEn;
