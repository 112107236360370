import styles from "@scss/pages/main/hero.module.scss";

type TProps = {
  onScrollToTarget: () => void;
};

export default function Hero({ onScrollToTarget }: TProps) {
  return (
    <section className={styles.wrapper}>
      <div className={styles.title}>
        <div>Win-Win MyData platform</div>

        <div>
          for <span className={styles.stress}>Users</span> and
          <span className={styles.stress}> Businesses</span>
        </div>
      </div>

      <div className={styles.des}>Regain Ownership and Monetize your Data</div>

      <button type="button" name="contact" onClick={onScrollToTarget}>
        Contact us
      </button>
    </section>
  );
}
