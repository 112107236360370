import cx from "classnames";
import styles from "@scss/pages/main/provide.module.scss";

export default function Provide() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.title}>What we provide</div>

      <dl>
        <div className={styles.pannel}>
          <div className={cx(styles.img_box, styles.myd)}>
            <img src="/img/provide/myd.png" alt="myd" />

            <div className={styles.type}>B2C</div>
          </div>

          <div className={styles.content}>
            <dt>my:D</dt>
            <dd>
              {`It's a platform that allows individuals to securely store MyData on their mobile phones, retain sovereignty over their data, and engage in direct transactions with businesses without concerns about personal information leakage.`}
            </dd>
          </div>
        </div>

        <div className={styles.pannel}>
          <div className={styles.img_box}>
            <img src="/img/provide/sdk.png" alt="sdk" />

            <div className={styles.type}>B2B</div>
          </div>

          <div className={styles.content}>
            <dt>ODMPASS SDK</dt>
            <dd>
              With integrating our SDK, businesses can securely store user data
              obtained through data transactions and deliver customized targeted
              advertising to specific users. <br /> By utilizing the pre-built
              features, companies can save both time and costs.
            </dd>
          </div>
        </div>

        <div className={styles.pannel}>
          <div className={styles.img_box}>
            <img src="/img/provide/connect.png" alt="connect" />

            <div className={styles.type}>B2B</div>
          </div>

          <div className={styles.content}>
            <dt>my:D CONNECT</dt>
            <dd>
              Through CONNECT, businesses can initiate date transaction
              requests, access the data they receive, and create targeted
              advertisements for specific audiences.
            </dd>
          </div>
        </div>
      </dl>
    </section>
  );
}
