import S from "./solution.module.scss";
import archImg from "../../assets/imgs/cloud-arch1-1.png";
import archImgSub from "../../assets/imgs/cloud-arch1-2.png";
import archImg2 from "../../assets/imgs/cloud-arch2.png";
import archImg3 from "../../assets/imgs/cloud-arch3.png";

export default function Cloud() {
  return (
    <div className={S.wrap}>
      <h2>
        중소기업용 클라우드 ERP 솔루션 <p>SNPFlow ERP ver 1.0</p>
      </h2>

      <div className={S.box}>
        <div className={S.head}>ERP 솔루션</div>
        <div className={S.desc}>
          중소기업 맞춤형으로 ERP의 주요 모듈인 인사/급여, 영업관리, 판매관리,
          구매관리, 재고관리 기능을 제공하는 클라우드 기반의 Solution
        </div>
      </div>

      <div className={S.box}>
        <div className={S.head}>필요성 및 도입 효과</div>

        <div className={S.sub_head} style={{ color: "#1CC5F7", marginTop: 16 }}>
          업무 환경의 변화
        </div>
        <div className={S.img_box}>
          <img src={archImg} alt="아키텍처" />
          <img src={archImgSub} alt="아키텍처" style={{ marginTop: 32 }} />
        </div>

        <div className={S.sub_head} style={{ color: "#1CC5F7", marginTop: 16 }}>
          시스템의 통합 및 접근성의 강화
        </div>
        <div className={S.img_box}>
          <img src={archImg2} alt="아키텍처" />
        </div>

        <div className={S.sub_head} style={{ color: "#1CC5F7", marginTop: 16 }}>
          시스템 통합의 효과
        </div>
        <ul className={S.util}>
          <div className={S.cloud_util_box}>
            <li>사용자 중심의 시스템으로 활용도 증가</li>
            <li>다양한 기기의 접근성으로 효율성 증가</li>
            <li>고 품질의 정보제공으로 실 활용도 증가</li>
          </div>

          <div className={S.cloud_util_box}>
            <li>정보 공유를 통한 원할 한 의사소통</li>
            <li>데이터의 통합으로 운영의 효율화</li>
            <li>실시간 처리로 사용자 만족도 증가</li>
          </div>

          <div className={S.cloud_util_box}>
            <li>인력 이동으로 인한 Risk감소</li>
            <li>신속한 업무 처리</li>
            <li>Big데이터를 통한 마케팅 전략수립</li>
          </div>
        </ul>
      </div>

      <div className={S.box}>
        <div className={S.head}>가격정책</div>

        <ul>
          <li>
            클라우드(SaaS) ERP서비스 사용료 월 100만원 (최대 사용자 100명까지)
          </li>
          <li>100명이상 고객은 별도 협의 요망</li>
          <li>영업담당자 : 임창범 팀장 (010-4566-2741)</li>
        </ul>
      </div>

      <h2>
        장비 임대형 바코드 시스템 <p>SNPScan</p>
      </h2>

      <div className={S.box}>
        <div className={S.head}>임대형 바코드 시스템</div>
        <div className={S.desc}>
          제조 및 유통 중소기업을 위한 임대형 바코드 시스템
        </div>
      </div>

      <div className={S.box}>
        <div className={S.head}>시스템 구성</div>
        <div className={S.img_box}>
          <img src={archImg3} alt="아키텍처" />
        </div>
      </div>

      <div className={S.box}>
        <div className={S.head}>가격정책</div>

        <ul>
          <li>
            임대형 바코드 시스템 사용료 월 800만원 (라벨 프린터 2대,스캐너 5대
            기본 제공)
          </li>
          <li>프린터 및 리더기 추가희망 고객은 별도 협의 요망</li>
          <li>영업담당자 : 임창범 팀장 (010-4566-2741)</li>
        </ul>
      </div>
    </div>
  );
}
