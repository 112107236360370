import styles from "@scss/pages/mainEn.module.scss";
import Paradigm from "./main/Paradigm";
import Hero from "./main/Hero";
import Collaboration from "./main/Collaboration";
import Characterizes from "./main/Characterizes";
import Technology from "./main/Technology";
import Solution from "./main/Solution";
import Provide from "./main/Provide";
import Case from "./main/Case";
import Team from "./main/Team";
import Contact from "./main/Contact";
import { useRef } from "react";

export default function MainEn() {
  const targetRef = useRef<HTMLDivElement>(null);

  const handleToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className={styles.wrapper}>
      <Hero onScrollToTarget={handleToTarget} />
      <Paradigm />
      <Collaboration />
      <Characterizes />
      <Technology />
      <Solution />
      <Provide />
      <Case />
      <Team />
      <div ref={targetRef}>
        <Contact />
      </div>
    </div>
  );
}
