import S from "@scss/component/main/partners.module.scss";

function Partners() {
  return (
    <>
      <p className={S.header}>고객사</p>
      <div className={S.partners}>
        <img src="/img/partners/씽씽.png" alt="씽씽" />
        <img src="/img/partners/농협금융.png" alt="농협금융" />
        <img src="/img/partners/서울.png" alt="서울" />
        <img src="/img/partners/경기도.png" alt="경기도" />
        <img src="/img/partners/태전.png" alt="태전" />
        <img src="/img/partners/금융과나눔.png" alt="금융과나눔" />
      </div>

      <p className={S.header}>투자 및 지원 그룹</p>
      <div className={S.partners}>
        <img src="/img/partners/신한.png" alt="신한" />
        <img src="/img/partners/농협.png" alt="농협" />
        <img src="/img/partners/스파크랩.png" alt="스파크랩" />
        <img src="/img/partners/tap.png" alt="tap" />
      </div>

      <p className={S.header}>학교</p>
      <div className={S.partners}>
        <img src="/img/partners/서울대.png" alt="서울대" />
        <img src="/img/partners/한양대.png" alt="한양대" />
        <img src="/img/partners/고려대.png" alt="고려대" />
        <img src="/img/partners/국민대.png" alt="국민대" />
        <img src="/img/partners/세종대.png" alt="세종대" />
        <img src="/img/partners/상명대.png" alt="상명대" />
        <img src="/img/partners/성신여대.png" alt="성신여대" />
      </div>

      <p className={S.header}>협력사 </p>
      <div className={S.partners}>
        <img src="/img/partners/엠코퍼레이션.png" alt="엠코퍼레이션" />
        <img src="/img/partners/서치스.png" alt="서치스" />
        <img src="/img/partners/코드에프.png" alt="코드에프" />
        <img src="/img/partners/ground.png" alt="ground" />
        <img src="/img/partners/coinplug.png" alt="coinplug" />
        <img src="/img/partners/아톰릭스랩.png" alt="아톰릭스랩" />
      </div>

      <p className={S.header}>기관</p>
      <div className={S.partners}>
        <img
          src="/img/partners/한국데이터산업진흥원.png"
          alt="한국데이터산업진흥원"
        />
        <img src="/img/partners/mydata.png" alt="mydata" />
        <img src="/img/partners/seoul_ai_hub.jpg" alt="seoul ai hub" />
      </div>
    </>
  );
}

export default Partners;
