/* eslint-disable react/no-unescaped-entities */
function DIDMethodSpec() {
  return (
    <>
      <h1 style={{ paddingTop: "100px" }}>
        <span style={{ color: "rgb(23, 43, 77)" }}>
          SNPLab DID Specification
        </span>
      </h1>
      <p style={{ lineHeight: "1.5" }}>
        <br />
      </p>
      <ol
        style={{
          margin: "0px",
          caretColor: "rgb(23, 43, 77)",
          color: "rgb(23, 43, 77)",
        }}
      >
        <li style={{ lineHeight: "1.5" }}>
          <p style={{ fontSize: "14px" }}>
            <strong>
              <span style={{ fontSize: "14pt" }}>DID Naming Scheme.</span>
            </strong>
          </p>
          <span style={{ fontSize: "12pt" }}>
            SNPLab DID format is "did:snplab:[method-specific-identification]"
          </span>
          <br />
          <ol style={{ margin: "0px", listStyleType: "lower-alpha" }}>
            <li style={{ fontSize: "14px", lineHeight: "1.5" }}>
              <p
                style={{
                  marginRight: "0px",
                  marginLeft: "0px",
                  padding: "0px",
                  lineHeight: "1.5",
                }}
              >
                <span style={{ fontSize: "12pt" }}>DID method Name</span>
                <br />
                <span style={{ fontSize: "12pt" }}>
                  DID method name of SNPLab DID is "snplab".
                </span>
                <br />
                <span style={{ fontSize: "12pt" }}>
                  In order to use SNPLab's DID service , DID SHOULD start with
                  "did:snplab:" and&nbsp;the prefix of DID method SHOULD be in
                  lowercase.
                </span>
                <br />
                <span style={{ fontSize: "12pt" }}>
                  The remainder of the DID is the method specific
                  identification(MSI) which is specified below.
                </span>
              </p>
            </li>
            <li style={{ fontSize: "14px", lineHeight: "1.5" }}>
              <span style={{ fontSize: "12pt" }}>
                Method specific Identification(MSI)
              </span>
              <br />
              <span style={{ fontSize: "12pt" }}>
                MSI in SNPLab DID format is the base54 encode value of whole
                Ed25519 public key(32 bytes) or half of it(16 bytes).
              </span>
              <br />
              <span style={{ fontSize: "12pt" }}>
                Therefore, the actual MSI size will be 22 ~ 44 bytes length.
              </span>
              <br />
              <ol style={{ margin: "0px", listStyleType: "lower-roman" }}>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "12pt" }}>
                    Generate private, public key pair through Ed25519 crypto
                    algorithm. The public key length is 32 bytes and the private
                    key length is 64 bytes.
                  </span>
                </li>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "12pt" }}>
                    Encode the public key by using base58 encoding scheme.
                  </span>
                </li>
              </ol>
            </li>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "12pt" }}>&nbsp;Example</span>
              <ol style={{ margin: "0px", listStyleType: "lower-roman" }}>
                <li style={{ fontSize: "14px", lineHeight: "1.5" }}>
                  <span style={{ fontSize: "12pt" }}>
                    22 Bytes MSI DID : &nbsp;"did:snplab:V4SGRU86Z58d6TV7PBUe6f"
                  </span>
                </li>
                <li style={{ fontSize: "14px", lineHeight: "1.5" }}>
                  <span style={{ fontSize: "12pt" }}>
                    44 Bytes MSI DID :
                    "did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKL"
                    <br />
                    <br />
                    <br />
                  </span>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li style={{ fontSize: "14px", lineHeight: "1.5" }}>
          <span style={{ fontSize: "14pt" }}>
            <strong>
              DID Document Format
              <br />
            </strong>
          </span>
          <span style={{ fontSize: "12pt" }}>
            DID document format is as below and includes base54 encoded public
            key value.
          </span>
          <ol style={{ margin: "0px", listStyleType: "lower-alpha" }}>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "12pt" }}>
                DID document format example
              </span>
              <ol style={{ margin: "0px", listStyleType: "lower-roman" }}>
                <li style={{ lineHeight: "1.5" }}>
                  <p
                    style={{
                      marginRight: "0px",
                      marginLeft: "0px",
                      padding: "0px",
                      lineHeight: "1.5",
                    }}
                  >
                    <span style={{ fontSize: "12pt" }}>22 Bytes MSI DID</span>
                    <br />
                    <span
                      style={{
                        fontSize: "12pt",
                        backgroundColor: "rgb(255, 255, 255)",
                      }}
                    >
                      &#123;
                    </span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>
                      <span style={{ backgroundColor: "rgb(255, 255, 255)" }}>
                        "@context":"https://
                        <a
                          href="http://www.w3.org/"
                          className="external-link"
                          rel="nofollow"
                          style={{
                            color: "rgb(0, 82, 204)",
                            WebkitTextDecorationStyle:
                              "var(--aui-link-decoration)",
                            textDecoration: "var(--aui-link-decoration)",
                            fontSize: "12pt",
                            backgroundColor: "rgb(255, 255, 255)",
                          }}
                        >
                          www.w3.org
                        </a>
                        /ns/did/v1",
                        <br />
                        "id":"did:snplab:V4SGRU86Z58d6TV7PBUe6f",
                        <br />
                        "verificationMethod":[
                        <br />
                        &nbsp; &nbsp; &nbsp; &nbsp;&#123;
                        <br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        "id":"did:snplab:V4SGRU86Z58d6TV7PBUe6f#verkey",
                        <br />
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp;"type":"Ed25519VerificationKey2018",
                        <br />
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp;"publicKeyBase58":"GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKL",
                        <br />
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp;"controller":"did:snplab:V4SGRU86Z58d6TV7PBUe6f"
                        <br />
                        &nbsp; &nbsp; &nbsp; &nbsp;&#125;
                        <br />
                        &nbsp; &nbsp; &nbsp; ],
                        <br />
                        "authentication":[
                        <br />
                        &nbsp; &nbsp; &nbsp;
                        "did:snplab:V4SGRU86Z58d6TV7PBUe6f#verkey"
                        <br />
                        &nbsp; &nbsp; &nbsp; ]
                        <br />
                        &#125;
                      </span>
                      <br />
                    </span>
                  </p>
                </li>
                <li style={{ lineHeight: "1.5" }}>
                  <p
                    style={{
                      marginRight: "0px",
                      marginLeft: "0px",
                      padding: "0px",
                      lineHeight: "1.5",
                    }}
                  >
                    <span style={{ fontSize: "12pt" }}>
                      &nbsp;44 Bytes MSI DID
                    </span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>&#123;</span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>
                      "@context":"https://
                      <a
                        href="http://www.w3.org/"
                        className="external-link"
                        rel="nofollow"
                        style={{
                          color: "rgb(0, 82, 204)",
                          WebkitTextDecorationStyle:
                            "var(--aui-link-decoration)",
                          textDecoration: "var(--aui-link-decoration)",
                          fontSize: "12pt",
                        }}
                      >
                        www.w3.org
                      </a>
                      /ns/did/v1",
                      <br />
                      "id":"did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKL",
                      <br />
                      "verificationMethod":[
                      <br />
                      &nbsp; &nbsp; &nbsp; &nbsp;&#123;
                      <br />
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;"id":"did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKL#verkey",
                      <br />
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;"type":"Ed25519VerificationKey2018",
                      <br />
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;"publicKeyBase58":"GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKL",
                      <br />
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;"controller":"did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKL"
                      <br />
                      &nbsp; &nbsp; &nbsp; &nbsp;&#125;
                      <br />
                      &nbsp; &nbsp; &nbsp; ],
                      <br />
                      "authentication":[
                      <br />
                      &nbsp; &nbsp; &nbsp;
                      "did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKL#verkey"
                      <br />
                      &nbsp; &nbsp; &nbsp; ]
                      <br />
                      &#125;
                      <br />
                    </span>
                    <br />
                    <br />
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li style={{ fontSize: "14px", lineHeight: "1.5" }}>
          <strong>
            <span style={{ fontSize: "14pt" }}>C.R. Operations Definition</span>
          </strong>
          <ol style={{ margin: "0px", listStyleType: "lower-alpha" }}>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "12pt" }}>X-Auth-Key</span>
              <br />
              <span style={{ fontSize: "12pt" }}>
                SNPLab uses X-Auth-Key method to allow only authenticated DID
                owners to register their DID.
              </span>
              <br />
              <span style={{ fontSize: "12pt" }}>
                Create and Delete operations require the X-Auth-Key value in the
                Header field of HTTP POST message.
              </span>
              <br />
              <span style={{ fontSize: "12pt" }}>
                The X-Auth-Key value is the SHA256 hash value of the
                concatenation of Application_Key, DID, Application_Version, and
                Time_Stamp.
              </span>
              <br />
              <br />
              <ol style={{ margin: "0px", listStyleType: "lower-roman" }}>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "12pt" }}>
                    X-Auth-Key generation test values
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    App_Version : "Test/1.0"
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    Application_Key : "1234567890abcdefghijklmnopqrstuvwxyz"
                  </span>
                  <br />
                  <br />
                </li>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "12pt" }}>
                    &nbsp;X-Auth-Key generation sample code
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    import java.security.MessageDigest;
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    import java.security.NoSuchAlgorithmException;
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    &nbsp; &nbsp; public className Main &#123;&nbsp;
                    <br />
                    &nbsp; &nbsp; &nbsp; &nbsp; public static void main(String[]
                    args) throws Exception &#123;
                    <br />
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; String hash =
                    null;
                    <br />
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; String appVersion
                    = "Test/1.0";
                    <br />
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; String appKey =
                    "1234567890abcdefghijklmnopqrstuvwxyz";
                    <br />
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; String did =
                    "did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKL";
                    <br />
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; long timestamp =
                    System.currentTimeMillis();
                    <br />
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; try &#123;
                    <br />
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; hash
                    = sha256(new String(appKey + did + appVersion + timestamp));
                    <br />
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    System.out.println(timestamp + "," + hash);
                    <br />
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &#125;&nbsp;catch
                    (NoSuchAlgorithmException e)&#123;
                    <br />
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    e.printStackTrace();
                    <br />
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &#125;
                    <br />
                    &nbsp; &nbsp; &nbsp; &nbsp; &#125;
                    <br />
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; public static String
                    sha256(String msg) throws NoSuchAlgorithmException &#123;
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; MessageDigest md =
                    MessageDigest.getInstance("SHA-256");
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    md.update(msg.getBytes());
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; return
                    bytesToHex(md.digest());
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; &#125;
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; public static String
                    bytesToHex(byte[] bytes) &#123;
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; StringBuilder
                    builder = new StringBuilder();
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; for (byte b:
                    bytes) &#123;
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    builder.append(String.format("%02x", b));
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &#125;
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; return
                    builder.toString();
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; &#125;
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>&nbsp; &nbsp; &#125;</span>
                </li>
              </ol>
            </li>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "12pt" }}>Create Operation</span>
              <br />
              <span style={{ fontSize: "12pt" }}>
                Creation of a SNPLab&nbsp;DID is triggered by the DID owner and
                Auth Server will execute a ledger transaction for a registration
                to the block chain network.
              </span>
              <br />
              <span style={{ fontSize: "12pt" }}>
                DID owner should call creation REST API (
                <a
                  href="https://openapi.myd.world/account/register"
                  className="external-link"
                  rel="nofollow"
                  style={{
                    color: "rgb(0, 82, 204)",
                    WebkitTextDecorationStyle: "var(--aui-link-decoration)",
                    textDecoration: "var(--aui-link-decoration)",
                    fontSize: "12pt",
                  }}
                >
                  https://openapi.myd.world/account/register
                </a>
                ) with a DID Document information in order to trigger the
                creation operation.
                <br />
                The REST API HTTP request will have X-Auth-Key value in the
                message head and DID document information in Json form will be
                in the request message body.
              </span>
              <ol style={{ margin: "0px", listStyleType: "lower-roman" }}>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "12pt" }}>
                    Creation operation request(curl) example
                  </span>
                  <br />
                  <span style={{ letterSpacing: "0px", fontSize: "12pt" }}>
                    curl -X POST "https://openapi.myd.world/account/register" -H
                    "accept: application/json" \<br />
                  </span>
                  <span style={{ letterSpacing: "0px", fontSize: "12pt" }}>
                    -H "X-Auth-Key:
                    3ff72cb5683d4cfe71874e77d86e8f53f28f9763a82b26d426deda4f5b894da3"
                    \<br />
                  </span>
                  <span style={{ fontSize: "12pt" }}>
                    -H "App-Version: Test/1.0" -H "Timestamp: 1632719881417" -H
                    "Content-Type: application/json" \
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    -d "&#123; \"@context\": \"https://www.w3.org/ns/did/v1\",
                    \"id\":
                    \"did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKM\",
                    \
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    \"verificationMethod\": [ &#123; \"id\":
                    \"did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKM#verkey\",
                    \
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    \"type\": \"Ed25519VerificationKey2018\", \"controller\":
                    \"did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKM\",
                    \
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    \"publicKeyBase58\":
                    \"GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKM\" &#125; ], \
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    \"authentication\": [
                    \"did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKM#verkey\"
                    ]&#125;"
                  </span>
                  <br />
                  <br />
                </li>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "12pt" }}>
                    Creation operation response example
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>&#123;</span>
                  <br />
                  <span style={{ fontSize: "12pt" }}>
                    &nbsp; "@context": "
                    <a
                      href="https://www.w3.org/ns/did/v1"
                      className="external-link"
                      rel="nofollow"
                      style={{
                        color: "rgb(0, 82, 204)",
                        WebkitTextDecorationStyle: "var(--aui-link-decoration)",
                        textDecoration: "var(--aui-link-decoration)",
                        fontSize: "12pt",
                      }}
                    >
                      https://www.w3.org/ns/did/v1
                    </a>
                    ",
                    <br />
                    &nbsp; "id":
                    "did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKM",
                    <br />
                    &nbsp; "verificationMethod": [<br />
                    &nbsp; &nbsp; &#123;
                    <br />
                    &nbsp; &nbsp; &nbsp; "id":
                    "did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKM#verkey",
                    <br />
                    &nbsp; &nbsp; &nbsp; "type": "Ed25519VerificationKey2018",
                    <br />
                    &nbsp; &nbsp; &nbsp; "controller":
                    "did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKM",
                    <br />
                    &nbsp; &nbsp; &nbsp; "publicKeyBase58":
                    "GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKM"
                    <br />
                    &nbsp; &nbsp; &#125;
                    <br />
                    &nbsp; ],
                    <br />
                    &nbsp; "authentication": [<br />
                    &nbsp; &nbsp;
                    "did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKM#verkey"
                    <br />
                    &nbsp; ]<br />
                    &#125;
                  </span>
                </li>
              </ol>
            </li>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "12pt" }}>Read Operation</span>
              <br />
              <span style={{ fontSize: "12pt" }}>
                DID Document can be accessible by calling read REST API(
                <a
                  href="https://openapi.myd.world/account/document"
                  className="external-link"
                  rel="nofollow"
                  style={{
                    color: "rgb(0, 82, 204)",
                    WebkitTextDecorationStyle: "var(--aui-link-decoration)",
                    textDecoration: "var(--aui-link-decoration)",
                    fontSize: "12pt",
                  }}
                >
                  https://openapi.myd.world/account/document
                </a>
                )<br />
                The RESP API HTTP request contains DID in the request message
                body but does not need to include X-Auth-Key value in the
                message header.
                <br />
              </span>
              <ol style={{ margin: "0px", listStyleType: "lower-roman" }}>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "12pt" }}>
                    Read operation request(curl) example
                  </span>
                  <p
                    style={{
                      marginRight: "0px",
                      marginLeft: "0px",
                      padding: "0px",
                      lineHeight: "1.5",
                    }}
                  >
                    <span style={{ fontSize: "12pt" }}>
                      curl -X POST "https://openapi.myd.world/account/document"
                      -H "accept: application/json" -H "Content-Type:
                      application/json" \
                    </span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>
                      -d "&#123; \"did\":
                      \"did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKM\"&#125;"
                    </span>
                  </p>
                </li>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "12pt" }}>
                    Read operation response example
                  </span>
                  <br />
                  <p
                    className=" microlight"
                    style={{
                      marginTop: "10px",
                      marginRight: "0px",
                      marginLeft: "0px",
                      padding: "0px",
                      lineHeight: "1.5",
                    }}
                  >
                    <span style={{ fontSize: "12pt" }}>&#123;</span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>
                      &nbsp; "created": "2021-09-27 14:19:50",
                    </span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>
                      &nbsp; "id":
                      "did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKM",
                    </span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>
                      &nbsp; "verificationMethod": [
                    </span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>
                      &nbsp; &nbsp; &#123;
                    </span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>
                      &nbsp; &nbsp; &nbsp; "publicKeyBase58":
                      "GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKM",
                    </span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>
                      &nbsp; &nbsp; &nbsp; "controller":
                      "did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKM",
                    </span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>
                      &nbsp; &nbsp; &nbsp; "id":
                      "did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKM#verkey",
                    </span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>
                      &nbsp; &nbsp; &nbsp; "type": "Ed25519VerificationKey2018"
                    </span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>
                      &nbsp; &nbsp; &#125;
                    </span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>&nbsp; &nbsp;],</span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>
                      &nbsp; &nbsp;"@context": "https://www.w3.org/ns/did/v1",
                    </span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>
                      &nbsp; &nbsp;"updated": "2021-09-27 14:19:50",
                    </span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>
                      &nbsp; &nbsp;"authentication": [
                    </span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>
                      &nbsp; &nbsp;
                      &nbsp;"did:snplab:GJ1SzoWzavQYfNL9XkaJdrQejfztN4XqdsiV4ct3LXKM#verkey"
                    </span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>&nbsp; &nbsp;]</span>
                    <br />
                    <span style={{ fontSize: "12pt" }}>
                      &#125;
                      <br />
                      <br />
                      <br />
                    </span>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li style={{ fontSize: "14px", lineHeight: "1.5" }}>
          <strong>
            <span style={{ fontSize: "14pt" }}>Security and Privacy</span>
          </strong>
          <ol style={{ margin: "0px", listStyleType: "lower-alpha" }}>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "12pt" }}>
                Personal user information will not be stored in the SNPLab block
                chain or Auth Server.
              </span>
            </li>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "12pt" }}>
                Public key and Private key are generated by user and only public
                key is stored in the SNPLab block chain.
              </span>
            </li>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "12pt" }}>
                X-Auth-Key method is introduced to restrict the access to write
                DID information into the SNPLab block chain.
              </span>
              <p style={{ lineHeight: "1.5" }}>
                <br />
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p style={{ lineHeight: "1.5" }}>
        <br className="Apple-interchange-newline" />
      </p>
    </>
  );
}

export default DIDMethodSpec;
