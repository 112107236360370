import styles from "@scss/pages/main/characterizes.module.scss";
import CharacterSVG from "../../assets/svg/character-sprite.svg";

export default function Characterizes() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.title}>We specialize in</div>

      <dl>
        <div className={styles.pannel}>
          <svg>
            <use href={`${CharacterSVG}#plus`} />
          </svg>

          <dt>Customizable data sources</dt>
          <dd>
            We possess the capability to ethically and securely extract and
            compile the data you desire, in accordance with your specifications.
          </dd>
        </div>

        <div className={styles.pannel}>
          <svg>
            <use href={`${CharacterSVG}#shield`} />
          </svg>

          <dt>Secure data storage</dt>
          <dd>
            {`Since personal data is only stored on the individual's mobile phone
            after encryption, there are no concerns about personal information
            leakage.`}
          </dd>
        </div>

        <div className={styles.pannel}>
          <svg>
            <use href={`${CharacterSVG}#check`} />
          </svg>

          <dt>Sharing anonymized data</dt>
          <dd>
            Once a user give a consent for sharing his/her data, the data will
            be anonymized, and sent directly to the business securely.
          </dd>
        </div>

        <div className={styles.pannel}>
          <svg>
            <use href={`${CharacterSVG}#building`} />
          </svg>

          <dt>Reward management system</dt>
          <dd>
            We support reward management system for businesses, facilitating
            user rewards through points, discounts, and other benefits. It
            streamlines the tracking and management of these reward
            transactions.
          </dd>
        </div>

        <div className={styles.pannel}>
          <svg>
            <use href={`${CharacterSVG}#mecaphone`} />
          </svg>

          <dt>Target marketing</dt>
          <dd>
            We have the technology to target and display ads only to the
            un-identified users that businesses require.
          </dd>
        </div>

        <div className={styles.pannel}>
          <svg>
            <use href={`${CharacterSVG}#check2`} />
          </svg>

          <dt>Data Compliance</dt>
          <dd>
            We provide customized information and personalized advertisements
            while adhering to legal standards and regulations.
          </dd>
        </div>
      </dl>
    </section>
  );
}
