import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import S from "@scss/pages/press.module.scss";

function Press() {
  const { t, i18n } = useTranslation();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [searchParams] = useSearchParams();
  const lang = searchParams.get("lang") || i18n.language;
  const isLangEn = lang === "en";

  useEffect(() => {
    const getSearch = new URLSearchParams(window.location.search).get(
      "latestURI"
    );

    if (getSearch && iframeRef.current) {
      iframeRef.current.src = getSearch;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      window.history.pushState("", "SNPLab", "/press");
    }
  });

  const translate = (txt: string) => {
    if (isLangEn) return t(txt);
    return txt;
  };

  const chkHeight = () => {
    if (!iframeRef.current) return;
    const height = Number(iframeRef.current.offsetHeight) + 200;
    iframeRef.current.style.height = `${height}px`;
  };

  return (
    <section className={S.press}>
      <div className={S.wrap}>
        <p className={S.header}>PRESS</p>

        <div className={S.desc}>
          <p>
            <span>
              <span>{translate("에스앤피랩의")} </span>
              <span> {translate("소식을 접해보세요")}</span>
            </span>
          </p>
          <p>
            <span>
              <span>{translate("에스앤피랩의 많은 활동들과")} </span>
              <span>{translate("소식들을 확인해보세요.")} </span>
            </span>
          </p>
        </div>

        <iframe
          ref={iframeRef}
          id="media"
          onLoad={chkHeight}
          src="https://snplab.io/mediacenter/"
          title="게시판"
        />
        {/* <iframe id="media" onLoad={chkHeight} src="http://localhost:8000/mediacenter/" title="게시판" /> */}
      </div>
    </section>
  );
}

export default Press;
