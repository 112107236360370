import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Popup from "../../component/about/Popup";
import team from "../../constants/team";

import S from "@scss/pages/about/team.module.scss";

function Team() {
  const { t, i18n } = useTranslation();

  const [searchParams] = useSearchParams();
  const lang = searchParams.get("lang") || i18n.language;
  const isLangEn = lang === "en";

  const [isShowing, setIsShowing] = useState(false);
  const [popupData, setPopupData] = useState({
    img: "",
    name: "",
    position: "",
    word: "",
    record: "",
  });

  const translate = (txt: string) => {
    if (isLangEn) return t(txt);
    return txt;
  };

  const openPopup = (
    getImg: string,
    getName: string,
    getPosition: string,
    getWord: string,
    getRecord: string
  ) => {
    setIsShowing(true);

    setPopupData({
      img: getImg,
      name: getName,
      position: getPosition,
      word: getWord,
      record: getRecord,
    });
  };

  const closePopup = () => {
    setIsShowing(false);
  };

  const addNullMember = (length: number) => {
    const result = [];

    const arr = team.filter((_, i) => parseInt(String(i / 4), 10) === length);
    for (let index = 0; index < 4 - arr.length; index++) {
      result.push(
        <div key={index}>
          <div className={S.img_container} style={{ cursor: "default" }}>
            <img src="/img/team/인재.png" alt="인재" />
          </div>
          <p>{translate("함께할 인재를 찾습니다.")}</p>
        </div>
      );
    }

    return result;
  };

  const addMember = (index: number) => {
    const result = [];

    const arr = team.filter((_, i) => parseInt(String(i / 4), 10) === index);
    for (const a of arr) {
      result.push(
        <button
          type="button"
          key={a.name + a.img}
          onClick={() => {
            openPopup(a.img, a.name, a.position, a.word, a.record);
          }}
        >
          <div className={S.img_container}>
            <img src={a.img} alt={a.name} />
          </div>
          <p>{translate(a.name)}</p>
        </button>
      );
    }

    return result;
  };

  return (
    <div className={S.team}>
      <div className={S.header}>
        <span>{translate("SNPLab의 ")}</span>
        <span>{translate("멤버를 소개합니다.")}</span>
      </div>

      <div className={S.leader}>
        <img src="/img/team/대표님.png" alt="대표님" />
        <div className={S["leader-desc"]}>
          <p>{translate("이재영")}</p>
          <p>
            <span>{translate("주요이력")}</span>
            <br />
            {translate("서울대 전기공학부(학) / 한양대 블록체인(석)")}
            <br />
            {translate("前 삼성전자 무선사업부 Security팀 수석연구원")}
            <br />
            {translate("모바일 보안 및 개인정보보호 전문가")}
            <br />
            - CIPP/E, CISSP, CEH, AFPK®
            <br />- {translate("‘핀테크와 GDPR’ 시리즈 저자")}
            <br />- {translate("금융연수원 핀테크 교재 집필위원")}
            <br />
            <br />
          </p>
          <p>
            <br />
            {translate("회사가 되게되게 중요하지만")}
            <br />
            {translate("팀원들의 인생은 되게되게되게")}
            <span> {translate("더 중요하기 때문에...")}</span>
          </p>
        </div>
      </div>

      <div className={S["team-desc"]}>
        {team
          .filter((_, i) => i % 4 === 0)
          .map((_, index) => (
            <div key={Math.random() + Number(index)}>
              {addMember(index)}
              {addNullMember(index)}
            </div>
          ))}
      </div>

      <div>
        <Popup data={popupData} open={isShowing} close={closePopup} />
      </div>
    </div>
  );
}

export default Team;
