import { ChangeEvent, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import Menu from "./Menu";
import { MENU_NAME } from "src/constants/menu";
import useScrollLevel from "src/hooks/useScroll";
import TopBtn from "../TopBtn";

import S from "@scss/component/common/header/headerEn.module.scss";

export default function HeaderEn() {
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const { scrollY } = useScrollLevel();

  const [searchParams, setSearchParams] = useSearchParams();

  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [tab, setTab] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isHeaderChanged, setIsHeaderChanged] = useState(true);

  const lang = searchParams.get("lang") || i18n.language;
  const isLangEn = lang === "en";

  useEffect(() => {
    window.addEventListener("resize", handleIsMobile);
    return () => {
      window.removeEventListener("resize", handleIsMobile);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY]);

  useEffect(() => {
    setTab(MENU_NAME.findIndex((el) => el.path === pathname) + 1);
    tab !== 1 ? setIsHeaderChanged(true) : setIsHeaderChanged(false);
    handleIsMobile();
  }, [tab, pathname]);

  const handleIsMobile = () => {
    window.innerWidth < 1024 ? setIsMobile(true) : setIsMobile(false);
  };

  const handleMobileMenu = () => {
    setIsOpenMobileMenu(!isOpenMobileMenu);
    if (isOpenMobileMenu) {
      setIsHeaderChanged(true);
    } else {
      setIsHeaderChanged(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleLang = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value === "ko") {
      setSearchParams({ lang: "ko" });
    } else {
      setSearchParams({ lang: "en" });
    }
  };

  return (
    <header
      className={cx(
        {
          [S["header-change"]]: isHeaderChanged,
          [S.hmobile]: isOpenMobileMenu,
        },
        S.hbg
      )}
    >
      <div className={S.wrap}>
        <Link
          to={isLangEn ? "/?lang=en" : "/"}
          className={isOpenMobileMenu ? S.hidden : ""}
          onClick={() => {
            setTab(1);
            scrollToTop();
          }}
        >
          <img className={S.logo} src="/img/logo_b.png" alt="logo" id="logo" />
        </Link>

        <nav>
          {MENU_NAME.map((element) => {
            return (
              <div key={element.id}>
                <NavLink
                  to={isLangEn ? `${element.path}?lang=en` : element.path}
                  onClick={() => {
                    setTab(element.id);
                    scrollToTop();
                  }}
                  className={element.id === tab ? S["menu-border"] : ""}
                >
                  <span className={S["menu-name"]}>{element.name}</span>
                </NavLink>
              </div>
            );
          })}

          <select
            className={cx(S["menu-language"], S.en)}
            onChange={handleLang}
            value={lang}
          >
            <option value="ko">KR</option>
            <option value="en">EN</option>
          </select>
        </nav>
        <div
          className={isMobile ? S["menu-btn"] : S.hide}
          aria-label="모바일 메뉴"
        >
          <button
            type="button"
            className={`${S.trigger} ${S["trigger-blue"]}`}
            onClick={handleMobileMenu}
          >
            <span className={isOpenMobileMenu ? S.active1 : ""} />
            <span className={isOpenMobileMenu ? S.active2 : ""} />
            <span className={isOpenMobileMenu ? S.active3 : ""} />
          </button>
        </div>
      </div>
      {!isMobile && <TopBtn />}
      <Menu
        open={isOpenMobileMenu}
        toggle={() => {
          scrollToTop();
          setIsOpenMobileMenu(!isOpenMobileMenu);
        }}
      />
    </header>
  );
}
