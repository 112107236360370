import styles from "@scss/pages/main/paradigm.module.scss";

export default function Paradigm() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.bg} />

      <div className={styles.content}>
        <div className={styles.title}>
          A new Paradigm in Personal Information Business
        </div>

        <dl>
          <div className={styles.pannel}>
            <dt>Partners company</dt>
            <dd>25&#43;</dd>
          </div>

          <div className={styles.pannel}>
            <dt>Data transaction count</dt>
            <dd>506K&#43;</dd>
          </div>

          <div className={styles.pannel}>
            <dt>Number of Users</dt>
            <dd>280K&#43;</dd>
          </div>

          <div className={styles.pannel}>
            <dt>Investment(USD)</dt>
            <dd>2M</dd>
          </div>
        </dl>
      </div>
    </section>
  );
}
