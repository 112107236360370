import { createPortal } from "react-dom";

import S from "@scss/component/about/popup.module.scss";

interface Props {
  close: VoidFunction;
  data: {
    img: string;
    name: string;
    position: string;
    word: string;
    record: string;
  };
  open: boolean;
}

const Popup = (props: Props) => {
  const getWord = props.data.word;
  const getRecord = props.data.record;

  getWord.replace("\n/g", "<br/>");
  getRecord.replace("\n/g", "<br/>");

  const closePopup = (e: React.MouseEvent) => {
    const { type, ariaLabel } = e.target as HTMLInputElement;
    if (type === "button" || ariaLabel === "closePopup") props.close();
  };

  return createPortal(
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={closePopup}
      aria-label="closePopup"
      className={
        props.open ? `${S["open-popup"]} ${S["popup-wrap"]}` : S["popup-wrap"]
      }
    >
      {props.open ? (
        <div className={S.popup}>
          <div className={S.header}>
            <button type="button" className={S.close} onClick={closePopup}>
              &times;
            </button>
          </div>

          <div className={S.contents}>
            <div className={S.img_container}>
              <img src={props.data.img} alt={props.data.name} />
            </div>
            <div className={S.desc}>
              <span>{props.data.name}</span>
              <span>{props.data.position}</span>
            </div>
            {props.data.record ? (
              <div className={S.word}>
                <span>주요이력</span>
                <div>
                  {getRecord.split("\n").map((record, index) => {
                    return (
                      <span key={Math.random() + Number(index)}>
                        {record}
                        <br />
                      </span>
                    );
                  })}
                </div>
              </div>
            ) : null}

            <div className={S.word}>
              <span>한마디</span>
              <div>
                {getWord.split("\n").map((line, index) => {
                  return (
                    <span key={Math.random() + Number(index)}>
                      {line}
                      <br />
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>,
    document.getElementById("popup")!
  );
};

export default Popup;
