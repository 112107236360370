import {
  Route,
  NavLink,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { useTranslation } from "react-i18next";

import Vision from "./Vision";
import GetSubMenu from "./GetSubMenu";

import S from "@scss/pages/about/about.module.scss";

function About() {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  const [searchParams] = useSearchParams();
  const lang = searchParams.get("lang") || i18n.language;
  const isLangEn = lang === "en";

  const translate = (txt: string) => {
    if (isLangEn) return t(txt);
    return txt;
  };

  return (
    <section className={S.about}>
      <div className={S["about-wrap"]}>
        <p className={S.header}>ABOUT US</p>

        <nav className={S["sub-menu"]}>
          <NavLink
            to={isLangEn ? ".?lang=en" : "."}
            className={(_) => (pathname === "/about" ? S.active : "")}
          >
            {translate("회사 소개")}
          </NavLink>
          <NavLink
            to={isLangEn ? "./team?lang=en" : "./team"}
            className={({ isActive }) => (isActive ? S.active : "")}
          >
            TEAM
          </NavLink>
        </nav>

        <Routes>
          <Route path="" element={<Vision />} />
          <Route path="/*" element={<GetSubMenu />} />
        </Routes>
      </div>
    </section>
  );
}

export default About;
