import styles from "@scss/pages/main/logoDesktop.module.scss";

export default function LogoDesktop() {
  return (
    <ul className={styles.wrapper}>
      <div className={styles.line}>
        <li>
          <img src="/img/collaboration/nonghyup.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/seoul.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/gyeonggido.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/taejeon.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/shinhan.png" alt="logo" />
        </li>

        <li>
          <img src="/img/collaboration/nh.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/spark.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/finance.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/tap.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/sing.png" alt="logo" />
        </li>
      </div>

      <div className={styles.line2}>
        <li>
          <img src="/img/collaboration/seouluni.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/hanyang.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/korea.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/kook.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/sejong.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/sang.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/sung.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/mcor.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/search.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/hecto.png" alt="logo" />
        </li>
      </div>

      <div className={styles.line2}>
        <li>
          <img src="/img/collaboration/ground.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/coin.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/lab.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/kdata.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/mydata.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/seoul_ai_hub.png" alt="logo" />
        </li>
        <li>
          <img src="/img/collaboration/vertical.png" alt="logo" />
        </li>
      </div>
    </ul>
  );
}
