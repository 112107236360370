import styles from "@scss/pages/main/case.module.scss";

export default function Case() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.title}>Case studies</div>

      <dl>
        <div className={styles.pannel}>
          <div className={styles.img_box}>
            <img src="/img/municipality.png" alt="municipality" />
          </div>

          <hr />

          <div className={styles.content}>
            <dt>Seoul Municipality (ESA-ON)</dt>
            <dd>
              {`Developed and launched 'ESA-ON' public app service,`}
              <br />a one-click administration service for moving by
              <br />
              utilizing SNPLab’s MyData technology
              <br />
              with on-device PDS.
            </dd>
          </div>
        </div>

        <div className={styles.pannel}>
          <div className={styles.img_box}>
            <img src="/img/customized.png" alt="customized" />
          </div>

          <hr />

          <div className={styles.content}>
            <dt>
              Customized Care Demand Analysis Based on MyData(Korea Univ.
              Hospital)
            </dt>
            <dd>
              Lifestyle data allows us to identify individuals in need of
              medical assistance. For example, those with shopping data related
              to diabetes, but with no assets in their financial data, could be
              potential candidates.
            </dd>
          </div>
        </div>
      </dl>
    </section>
  );
}
