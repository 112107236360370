import styles from "@scss/pages/main/technology.module.scss";
import TechnologySVG from "../../assets/svg/technology-sprite.svg";

export default function Technology() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.title}>Our Technology</div>

      <dl>
        <div className={styles.pannel}>
          <div className={styles.content}>
            <dt>Blockchain</dt>
            <dd>
              {`Blockchain is employed to transparently log all transactions,
            ensuring that they remain immutable, even by SNPLAB. It's important
            to note that no user data is recorded on the blockchain.`}
            </dd>
          </div>

          <svg width={23} height={24}>
            <use href={`${TechnologySVG}#blockchain`} />
          </svg>
        </div>

        <div className={styles.pannel}>
          <div className={styles.content}>
            <dt>
              self-issue DID <br /> (Decentralized Identifier)
            </dt>
            <dd>
              {`The user's phone independently generates a self-issued DID, which is then shared with businesses as an identifier. This ensures that businesses cannot identify the individual, but can recognize that the data originates from the same source.`}
            </dd>
          </div>

          <svg width={22} height={32}>
            <use href={`${TechnologySVG}#did`} />
          </svg>
        </div>

        <div className={styles.pannel}>
          <div className={styles.content}>
            <dt>AI-based personalized non-identifiable targeted marketing</dt>
            <dd>
              Businesses have the capability to register tailored advertisements
              with precision, enabling them to engage specific audiences by
              leveraging factors like demographics, interests, and preferences.
            </dd>
          </div>

          <svg width={23} height={21}>
            <use href={`${TechnologySVG}#ai`} />
          </svg>
        </div>

        <div className={styles.pannel}>
          <div className={styles.content}>
            <dt>Scraping</dt>
            <dd>
              Data scraping technology automatically collects and extracts data
              from websites and online sources, encompassing various data types
              such as text, links, etc., used for analysis, research, and
              business information monitoring.
            </dd>
          </div>

          <svg width={22} height={22}>
            <use href={`${TechnologySVG}#scraping`} />
          </svg>
        </div>

        <div className={styles.pannel}>
          <div className={styles.content}>
            <dt>Encrypted PDS</dt>
            <dd>
              {` All user data is encrypted using NIST-recommended cipher suites and
            stored on the user's mobile phone. This method safeguards data
            against unauthorized access and leaks, providing a secure means to
            store sensitive information or personal data.`}
            </dd>
          </div>

          <svg width={23} height={23}>
            <use href={`${TechnologySVG}#pds`} />
          </svg>
        </div>

        <div className={styles.pannel}>
          <div className={styles.content}>
            <dt>Data anonymization</dt>
            <dd>
              Data anonymization is the process of removing or replacing
              identifiable information in personal data to enhance privacy. It
              protects sensitive information but the data still can be used for
              various way such as analysis, research, and machine learning.
            </dd>
          </div>

          <svg width={23} height={26}>
            <use href={`${TechnologySVG}#data`} />
          </svg>
        </div>
      </dl>
    </section>
  );
}
