import styles from "@scss/pages/main/solution.module.scss";

export default function Solution() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.title}>Our Solution</div>

      <div className={styles.des}>
        {`  A platform where users regain ownership of their personal data, monetize
        it, and share data with businesses, allowing businesses to gain insights
        through targeted ads in a de-identified (anonymous) manner`}
      </div>

      <div className={styles.img_box}>
        <img src="/img/solution.png" alt="solution" />
      </div>
    </section>
  );
}
