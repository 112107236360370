import { useLocation } from "react-router-dom";
import Team from "./Team";

function GetSubMenu() {
  const { pathname } = useLocation();

  return pathname.split("/").pop() === "team" ? <Team /> : null;
}

export default GetSubMenu;
