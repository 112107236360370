import styles from "@scss/pages/main/contact.module.scss";

export default function Contact() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.content}>
        {`Whether you're looking for a specific business solution or just need
        some questions answered, we're here to help`}
        <br />
        If you have any questions, please contact the e-mail address below
      </div>

      <div className={styles.email}>hellodata@snplab.io</div>
    </section>
  );
}
