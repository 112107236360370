import { useTranslation } from "react-i18next";
import FooterKo from "./FooterKo";
import FooterEn from "./FooterEn";

function Footer() {
  const { i18n } = useTranslation();

  return (
    <>
      {i18n.language === "ko" && <FooterKo />}
      {i18n.language !== "ko" && <FooterEn />}
    </>
  );
}

export default Footer;
