import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEn from "./translation.en.json";

const resource = {
  en: {
    translation: translationEn,
  },
};

const lang = (navigator.language || navigator.userLanguage).substr(0, 2); // e.g. en-US -> en

i18n.use(initReactI18next).init({
  resources: resource,
  lng: lang, // 초기 설정 언어
  fallbackLng: lang,
  debug: false,
  defaultNS: "translation",
  ns: "translation",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
