import S from "@scss/pages/notfound.module.scss";

function NotFound() {
  return (
    <div className={S.container}>
      <span>요청하신 페이지를 찾을 수 없습니다</span>
      <br />
      <p>
        이전 페이지로 돌아가시거나, &nbsp;
        <a href="/">여기</a>를 눌러 SNPLAB 홈으로 돌아가세요
      </p>
    </div>
  );
}

export default NotFound;
