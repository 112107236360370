import { useState } from "react";

import S from "@scss/pages/technology.module.scss";

function Tech() {
  const [status, setStatus] = useState(true);

  window.addEventListener("resize", () => {
    if (window.innerWidth >= 1024) setStatus(true);
    else setStatus(false);
  });

  return (
    <section className={S.technology}>
      <div className={S.wrap}>
        <p className={S.header}>OUR TECHNOLOGY</p>
        <div className={S.desc}>
          <p>
            마이데이터 기업 에스앤피랩은
            <br />
            개인과 기업의 연결을 위하여 다음과 같이 노력하고 있습니다
          </p>
          <p>
            <span>
              에스앤피랩의 기술은 개인의 비식별화에서 끝나는 것이 아닌,{" "}
            </span>
            <span>비식별화된 개인의 정보의 연결까지 이어지는 것입니다.</span>
          </p>
        </div>
        <div className={S.card}>
          <div>
            <span>DID를 이용한 On-Device 개인 데이터 공유</span>
            <p>
              DID는 디지털 신원확인 절차에 혁신적 간편함과 신뢰성을 제공하여
              이용자가 {status && <br />}발행자를 거치지 않고 자신의 신원정보를
              선별적으로 제공 할 수 있도록 하는 {status && <br />}
              자기주권형ID(Self-Soverign Identity) 신원 인증 기술입니다.
            </p>
            <p>
              SNPLab의 개인정보 플랫폼은 탈중앙 식별자를 이용하여 개인은 쉽게{" "}
              {status && <br />}개인정보를 선택하여 제공하고 기업은 제공된
              정보의 신뢰를 검증할 수 있는
              <br />
              차별화 된 기술을 제공합니다.
            </p>
          </div>
          <img src="/img/technology/image.png" alt="technology1" />
        </div>
        <div className={S.card}>
          <div>
            <span>LDP 통계</span>
            <p>
              On-Device 마이데이터 플랫폼의 개인정보 저장소 정보를 기업이 쿼리
              형태로 {status && <br />}이용 LDP(Local Differential Privacy)
              기술을 사용한 사용자 프라이버시 보호
            </p>
            <p>
              *LDP : 개인정보의 수집 단계에서 개인정보를 보호를 위해 데이터를
              변형 후 {status && <br />}수집하고, 수집된 데이터 분석시에 수학적
              모델을 통한 통계적인 결과 유추하는 프라이버시 보호 기술
            </p>
          </div>
          <img src="/img/technology/image.png" alt="technology2" />
        </div>
        <div className={S.card}>
          <div>
            <span>개인정보 이용권</span>
            <p>
              법적 “동의”요건을 모두 갖추고 개인정보의 이용의 편의성과 관리의
              편의성을 {status && <br />}모두 높여주도록 개인정보를 “개인정보
              이용권”으로 디지털 자산화
            </p>
            <p>
              개인정보 이용의 승인 및 회수, 그리고 정량적 보상이 가능하도록
              개인정보를 {status && <br />}기간 및 횟수로 블록체인의 전자계약을
              통한 제어 기술 제공
            </p>
          </div>
          <img src="/img/technology/image.png" alt="technology3" />
        </div>
        <div className={`${S.card} ${S["change-position"]}`}>
          <div>
            <span>선행지표</span>
            <p>
              법적 “동의”요건을 모두 갖추고 개인정보의 이용의 편의성과 관리의
              편의성을 {status && <br />}모두 높여주도록 개인정보를 “개인정보
              이용권”으로 디지털 자산화
            </p>
            <p>
              개인정보 이용의 승인 및 회수, 그리고 정량적 보상이 가능하도록
              개인정보를 {status && <br />}기간 및 횟수로 블록체인의 전자계약을
              통한 제어 기술 제공
            </p>
          </div>
          <img src="/img/technology/image.png" alt="technology4" />
        </div>
        <div className={`${S.card} ${S["change-position"]}`}>
          <div>
            <span>마이디 데이터 저장소</span>
            <p>
              개인단말에 위치, 서비스 연결을 통해 개인 생활속에 생성되는 정보를{" "}
              {status && <br />}수집 및 저장하여 최대 6개월간의 데이터를
              저장합니다.
            </p>
            <p>
              데이터 카테고리는 금융, 건강, 생활로 나눠지며 서비스 연결방식은{" "}
              {status && <br />}공인인증서, ID/ PW를 통해 수집됩니다.
            </p>
          </div>
          <img src="/img/technology/image.png" alt="technology5" />
        </div>
        <div className={`${S.card} ${S["change-position"]}`}>
          <div>
            <span>라이프 로그 서비스</span>
            <p>
              법적 “동의”요건을 모두 갖추고 개인정보의 이용의 편의성과 관리의
              편의성을 {status && <br />}모두 높여주도록 개인정보를 “개인정보
              이용권”으로 디지털 자산화
            </p>
            <p>
              개인정보 이용의 승인 및 회수, 그리고 정량적 보상이 가능하도록
              개인정보를 {status && <br />}기간 및 횟수로 블록체인의 전자계약을
              통한 제어 기술 제공
            </p>
          </div>
          <img src="/img/technology/image.png" alt="technology6" />
        </div>
      </div>
    </section>
  );
}

export default Tech;
