/**
 * 주요 직책 / 팀장 / 파트장 / 사원 순으로, 가나다 정렬입니다.
 * 주요 직책만 record를 작성하고, 사원은 position을 작성하지 않습니다.
 */
const team = [
  {
    img: "/img/team/창기님.png",
    name: "정창기",
    position: "CTO",
    word: "즐겁게 사용할 수 있는 제품을 개발해 봅시다~",
    record: "서울대 물리 (학)\n前 올리브헬스케어 CTO",
  },
  {
    img: "/img/team/서영규님.jpg",
    name: "서영규",
    position: "CISO / CPO",
    word: "Let’s all be happy.",
    record: "동국대 국제정보대학원 정보보호 (석)\n前 SK온 부사장",
  },
  {
    img: "/img/team/지윤님.png",
    name: "이지윤",
    position: "경영지원실장",
    word: "어제의 나와 경쟁하는 사람 !!",
    record: "",
  },
  {
    img: "/img/team/경훈님.png",
    name: "곽경훈",
    position: "플랫폼 개발실",
    word: "잘 부탁드립니다!",
    record: "",
  },
  {
    img: "/img/team/윤상현님.jpg",
    name: "윤상현",
    position: "경영지원실",
    word: "안녕하십니까",
    record: "",
  },
  {
    img: "/img/team/창범님.jpg",
    name: "임창범",
    position: "영업 팀",
    word: "화이팅",
    record: "",
  },
  {
    img: "/img/team/상원님.jpeg",
    name: "황상원",
    position: "솔루션 개발실",
    word: "잘부탁드립니다. SNP 화이팅!",
    record: "",
  },
  {
    img: "/img/team/철현님.png",
    name: "안철현",
    position: "",
    word: "이걸 끼고 일해야 능률이 올라갑니다",
    record: "",
  },
  {
    img: "/img/team/영하님.png",
    name: "차영하",
    position: "",
    word: "",
    record: "",
  },
  {
    img: "/img/team/민준님.png",
    name: "김민준",
    position: "",
    word: "항상 감사합니다😊",
    record: "",
  },
  {
    img: "/img/team/김민준님_마케팅.jpg",
    name: "김민준",
    position: "",
    word: "착한 사람이 되겠습니다.",
    record: "",
  },
  {
    img: "/img/team/김유리님.png",
    name: "김유리",
    position: "",
    word: "안녕하세요",
    record: "",
  },
  {
    img: "/img/team/준엽님.jpg",
    name: "김준엽",
    position: "",
    word: "화이팅!",
    record: "",
  },
  {
    img: "/img/team/김하원님.png",
    name: "김하원",
    position: "",
    word: "어려운 길은 아름다운 목적지로!",
    record: "",
  },
  {
    img: "/img/team/박소륜님.png",
    name: "박소륜",
    position: "",
    word: "하쿠나마타타",
    record: "",
  },
  {
    img: "/img/team/충암님.png",
    name: "박충암",
    position: "",
    word: "안녕하십니까! 잘 부탁드립니다!",
    record: "",
  },
  {
    img: "/img/team/동욱님.jpg",
    name: "정동욱",
    position: "",
    word: "Ad astra, per aspera. (역경을 극복하고 성공하다.)",
    record: "",
  },
  {
    img: "/img/team/한지수님.png",
    name: "한지수",
    position: "",
    word: "직선이 아니어도 괜찮아",
    record: "",
  },
];

export default team;
