import { useEffect, useState } from "react";

function useScroll() {
  const [scrollLevel, setScrollLevel] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const [documentHeight, setDocumentHeight] = useState(0);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY]);

  const handleScroll = () => {
    const screen = window.screen.height;
    setScrollY(document.documentElement.scrollTop);
    setDocumentHeight(document.documentElement.offsetHeight);
    if (scrollY + screen < documentHeight * (1 / 5)) {
      setScrollLevel(1);
    } else if (scrollY + screen < documentHeight * (2 / 5)) {
      setScrollLevel(2);
    } else if (scrollY + screen < documentHeight * (3 / 5)) {
      setScrollLevel(3);
    } else if (scrollY + screen < documentHeight * (4 / 5)) {
      setScrollLevel(4);
    } else setScrollLevel(5);
  };

  return { scrollLevel, scrollY, documentHeight };
}

export default useScroll;
