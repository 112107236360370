import S from "@scss/component/common/footerEn.module.scss";
import instagramSvg from "../../assets/svg/instagram.svg";
import linkedinSvg from "../../assets/svg/linkedin.svg";
import mediumSvg from "../../assets/svg/medium.svg";

export default function FooterEn() {
  const insta = () => {
    window.open("https://www.instagram.com/snplab_global/");
  };

  const linkedin = () => {
    window.open("https://www.linkedin.com/company/snplab/");
  };

  const medium = () => {
    window.open("https://myd-app.medium.com/");
  };

  return (
    <footer className={S.wrapper}>
      <div className={S.header}>
        <img src="/img/logo.png" alt="로고" />

        <div className={S.sns}>
          <button type="button" onClick={medium}>
            <img src={mediumSvg} alt="medium" />
          </button>
          <button type="button" onClick={linkedin}>
            <img src={linkedinSvg} alt="linkedin" />
          </button>
          <button type="button" onClick={insta}>
            <img src={instagramSvg} alt="instagram" />
          </button>
        </div>
      </div>

      <div className={S.desc}>
        <div>© SNPLab Inc. All rights reserved.</div>
        <div>SNPLAB co.,ltd. | Michael Lee (CEO) |</div>
        <div>Business License Number 889-87-01698 |</div>
        <div>E-mail hellodata@snplab.io |</div>
        <div>T 02-2038-2511 | F 050-7086-2235 |</div>
        <div>
          Head Office : 135, Jungdaero, Songpa-gu, Seoul, 10th floor, 1003
        </div>
        <div>R&D Center: 16, Maeheon-ro, Seocho-gu, Seoul, 14th floor 1414</div>
      </div>
    </footer>
  );
}
