import S from "./solution.module.scss";
import archImg from "../../assets/imgs/service-arch.png";
import archImg2 from "../../assets/imgs/service-arch2.png";
import archImg3 from "../../assets/imgs/service-arch3.png";
import archImg4 from "../../assets/imgs/service-arch4.png";
import archArrowImg4 from "../../assets/imgs/service-arch4-arrow.png";
import archImg5 from "../../assets/imgs/service-arch5.png";
import archSubImg5 from "../../assets/imgs/service-arch5-sub.png";

export default function Solution() {
  return (
    <div className={S.wrap}>
      <h2>
        인공 지능 컴퓨터 비전 솔루션 <p>(SNPVision ver 1.0)</p>
      </h2>

      <div className={S.box}>
        <div className={S.head}>컴퓨터비전 솔루션</div>
        <div className={S.desc}>
          이미지나 영상 등의 Classification, Detection, Tracing, Segmentation
          등을 통한 AI 분석 및 결과 도출하는 Solution
        </div>
      </div>

      <div className={S.box}>
        <div className={S.head}>아키텍처</div>
        <div className={S.desc}>
          데이터 정의 – 전처리 – 알고리즘 선택 – 파라미터 튜닝 – 결과 생성 및
          배포, 관리의 순으로 진행
        </div>
        <div className={S.img_box}>
          <img src={archImg} alt="아키텍처" />
        </div>
      </div>

      <div className={S.box}>
        <div className={S.head}>활용 분야</div>
        <div className={S.sub_head}>제조업계 활용 분야</div>
        <ul className={S.util}>
          <li>제품의 외관 규격 이상 여부 판정</li>
          <li>제품의 크기 규격 측정</li>
        </ul>

        <div className={S.sub_head}>제공 방안</div>
        <ul className={S.util}>
          <li>구축형 / 통상 실시</li>
          <li>
            라이선스 정책 : 사이트 적용 업무당 1라이선스 적용(2Copy 이상 구매시
            할인 적용)
          </li>
        </ul>
      </div>

      <h2 style={{ marginTop: 100 }}>
        <span style={{ letterSpacing: -2.4 }}>
          인공지능 기반 예측 및 분류 솔루션
        </span>{" "}
        <p>(SNP CataLogix ver 1.0)</p>
      </h2>

      <div className={S.box}>
        <div className={S.head}>이상탐지 솔루션</div>
        <div className={S.desc}>
          사이버보안, 금융분야, 기계결함 탐지와 같은 정상 데이터와 다른 특성을
          갖는 결함 데이터를 찾아내는 Anomaly Detection
        </div>
      </div>

      <div className={S.box}>
        <div className={S.head}>아키텍처</div>
        <div className={S.desc}>
          데이터 정의 – 전처리 – 알고리즘 선택 – 파라미터 튜닝 – 결과 생성 및
          배포, 관리의 순으로 진행
        </div>
        <div className={S.img_box}>
          <img src={archImg2} alt="아키텍처" />
        </div>
      </div>

      <div className={S.box}>
        <div className={S.head}>활용 분야</div>
        <div className={S.sub_head}>유통업계 활용 분야</div>
        <ul className={S.util}>
          <li>
            식자재 유통업체에서 연단위 사전 일괄 납품계약에 대비한 식자재별 가격
            예측
          </li>
        </ul>

        <div className={S.sub_head}>제조업계 활용 분야</div>
        <ul className={S.util}>
          <li>
            사출공장에서 대량으로 생성되는 제품의 품질 규격 이탈 여부 예측
          </li>
        </ul>

        <div className={S.sub_head}>의료계 활용 분야</div>
        <ul className={S.util}>
          <li>각종 검사 결과에 따른 적절한 약물 처방 추천</li>
          <li>혈액투석 환자의 혈관 접근로(AVF) 수술 시기 예측</li>
        </ul>

        <div className={S.sub_head}>제공 방안</div>
        <ul className={S.util}>
          <li>구축형 / 통상 실시</li>
          <li>
            라이선스 정책 : 사이트 적용 업무당 1라이선스 적용(2Copy 이상 구매시
            할인 적용)
          </li>
        </ul>
      </div>

      <h2 style={{ marginTop: 100 }}>
        연합학습 관리 솔루션 <p>(SNP FL-STUDIO ver.1.0)</p>
      </h2>

      <div className={S.box}>
        <div className={S.head}>연합학습 관리 솔루션</div>
        <div className={S.desc}>
          분산 환경에서 데이터가 아니라 모델을 전송하여 훈련하고 병합하는 방식인
          연합학습 기법을 적용한 기계학습 관리 솔루션
        </div>
      </div>

      <div className={S.box}>
        <div className={S.head}>아키텍처</div>
        <div className={S.desc}>
          연합 학습은 데이터를 지역에 둔 상태로 학습하는 방식. 중앙 집중식에
          비하여 데이터 보호에 유리
        </div>
        <div className={S.img_box}>
          <img src={archImg3} alt="아키텍처" />
        </div>
      </div>

      <div className={S.box}>
        <div className={S.head}>활용 분야</div>
        <div className={S.sub_head}>군부대 / 의료기관 / 스마트폰 등</div>
        <ul className={S.util}>
          <li>
            정보보호를 위해 기관 간 데이터 이동이 허용되지 않는 상황에서의
            기계학습
          </li>
        </ul>

        <div className={S.sub_head}>제공 방안</div>
        <ul className={S.util}>
          <li>구축형 / 통상 실시</li>
          <li>
            라이선스 정책 : 사이트 적용 업무당 1라이선스 적용(2Copy 이상 구매시
            할인 적용)
          </li>
        </ul>
      </div>

      <div className={S.box}>
        <div className={S.head}>활용 방식</div>
        <div className={S.desc}>
          <p style={{ marginTop: 0 }}>
            지역별로 저장되어 있는 데이터를 중앙으로 모으지 않고 AI 모델 개발
            하는 것을 가능하게 함
          </p>
          <p>
            AI 모델 개발 기관에게 데이터를 제공하지 않은 상태로 협업할 수 있게
            하는 인터페이스 제공
          </p>
        </div>
        <div className={S.img_box}>
          <img src={archImg4} alt="아키텍처" />
          <img src={archArrowImg4} style={{ marginTop: 30 }} alt="아키텍처" />
        </div>
      </div>

      <h2 style={{ marginTop: 100 }}>
        사설(Private) LLM 솔루션 <p>(SNPBrain ver.1.0)</p>
      </h2>

      <div className={S.box}>
        <div className={S.head}>Private LLM 솔루션</div>
        <div className={S.desc}>
          <p style={{ marginTop: 0, letterSpacing: -0.4 }}>
            외부 상용 GenAI제공 업체의 서버가 아닌 독자적(private)으로 구축된
            서버에 해당 조직의 정보를 업로드하여 원하는 답변을 생성하는 Private
            LLM 솔루션
          </p>
        </div>
      </div>

      <div className={S.box}>
        <div className={S.head}>아키텍처</div>
        <div className={S.img_box}>
          <img src={archImg5} alt="아키텍처" />
          <img src={archSubImg5} style={{ marginTop: 30 }} alt="아키텍처" />
        </div>
      </div>

      <div className={S.box}>
        <div className={S.head}>활용분야</div>

        <div className={S.sub_head}>제조업계 활용 분야</div>
        <ul className={S.util}>
          <li>
            부품코드가 주어졌을 때 해당 부품의 상세 스펙을 카탈로그로부터
            추출하여 결과 조회
          </li>
          <div className={S.eg_box}>
            <div style={{ fontWeight: 700 }}>
              eg. 삼성전기의 MLCC 코드 : CL21A226MAQNNNE
            </div>
            <ul>
              <li>크기/형태: 21 → 0805</li>
              <li>용량: 226 → 22UF (226 = 22 × 10^6 pF = 22 µF).</li>
              <li>정밀도: M → M(20%).</li>
              <li>전압: 25V</li>
            </ul>
          </div>

          <li>
            제품의 매뉴얼로부터 챗봇을 통하여 원하는 답변을 얻고자 하는 경우
          </li>
        </ul>

        <div className={S.sub_head}>법률 활용 분야</div>
        <ul className={S.util}>
          <li>
            (파산 분야 예시) 파산을 신청하고자 하는 사람이 변호사의 도움없이
            복잡한 제반 행정 서류를 준비하고자 하는 경우
          </li>
        </ul>
      </div>
    </div>
  );
}
